import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { fDate, getUniqueKey } from '../../utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const programAttributeRows = [
  { id: 'a', title: "Driver's Minimum Wage / Minute", keyValue: 'minWgPrMnt' },
  { id: 'b', title: 'Engaged Time Earnings Factor', keyValue: 'engTmErnFact' },
  {
    id: 'c',
    title: 'Final Guaranteed Engaged Time Earnings Rate / Minute',
    formula: '(a * b)',
    keyValue: 'engTmErnRtPrMnt',
  },
  { id: 'd', title: 'Engaged Mile Earnings', keyValue: 'engMlErnRtPrtMl' },
  { id: 'e', title: 'Period 2 Referral Zone Engaged Miles', keyValue: 'engMlVal' },
  { id: 'f', title: 'Period 2 Referral Zone Engaged Minutes', keyValue: 'engTmVal' },
  { id: 'g', title: 'Loading Time Engaged Minutes - A LOS', keyValue: 'ldEngTmValA' },
  { id: 'h', title: 'Loading Time Engaged Minutes - ADD LOS', keyValue: 'ldEngTmValAdd' },
  { id: 'i', title: 'Unloading Time Engaged Minutes - A LOS', keyValue: 'unLdEngTmValA' },
  { id: 'j', title: 'Unloading Time Engaged Minutes - ADD LOS', keyValue: 'unLdEngTmValAdd' },
];
const tripCalculatorRows = [
  {
    id: 'o',
    title: 'Guaranteed Earnings Subtotal: Stage 2 Time',
    formula: '(b * f)',
    keyValue: 'sbTlEngTmPkp',
  },
  {
    id: 'p',
    title: 'Guaranteed Earnings Subtotal: Stage 2 Miles',
    formula: '(d * e)',
    keyValue: 'sbTlEngMlPkp',
  },
  {
    id: 'q',
    title: 'Guaranteed Earnings Subtotal:   Load',
    formula: '(b * g) OR (b * h)',
    keyValue: 'sbTlEngLd',
  },
  { id: 'r', title: 'Google Maps Estimated Time', keyValue: 'gglTm' },
  {
    id: 's',
    title: 'Guaranteed Earnings Subtotal:  Stage 3 Engaged Drive Time',
    formula: '(b * r )',
    keyValue: 'sbTlEngDrvTm',
  },
  { id: 't', title: 'Google Maps Estimated Miles', keyValue: 'gglMl' },
  {
    id: 'u',
    title: 'Guaranteed Earnings Subtotal:  Stage 3 Engage Drive Mileage',
    formula: '(d * t)',
    keyValue: 'sbTlEngDrvMl',
  },
  {
    id: 'v',
    title: 'Guaranteed Earnings Subtotal:  Unloading Engaged Time',
    formula: '(b * i) OR (b * j)',
    keyValue: 'sbTlEngUnLd',
  },
];
const tripGEarningRows = [
  { id: 'w', title: 'Trip Guaranteed Earnings', formula: '(o + p + q + s + u + v)', keyValue: 'trpErn' },
];

export default function DriverCalculatedPaymentTable(props) {
  return (
    <Grid container justifyContent="space-between" alignItems="flex-start" spacing={2}>
      <Grid item xs={12} md={4}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '100%' }} size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Serial</StyledTableCell>
                <StyledTableCell>Program Attribute</StyledTableCell>
                <StyledTableCell align="right">Value</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {programAttributeRows.map(
                (row) =>
                  props?.providerTripDetails?.calculationDetails[row.keyValue] && (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        <Typography variant="subtitle2" align="center">
                          {row.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.title}
                        <Typography variant="subtitle2">{row?.formula}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {props?.providerTripDetails?.calculationDetails[row.keyValue]}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '100%' }} size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Serial</StyledTableCell>
                <StyledTableCell>Trip Calculator</StyledTableCell>
                <StyledTableCell align="right">Value</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tripCalculatorRows.map(
                (row) =>
                  props?.providerTripDetails?.calculationDetails[row.keyValue] && (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        <Typography variant="subtitle2" align="center">
                          {row.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.title}
                        <Typography variant="subtitle2">{row?.formula}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {props?.providerTripDetails?.calculationDetails[row.keyValue]}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '100%' }} size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Serial</StyledTableCell>
                <StyledTableCell>Trip Guaranteed Earnings</StyledTableCell>
                <StyledTableCell align="right">Value</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tripGEarningRows.map(
                (row) =>
                  props?.providerTripDetails?.calculationDetails[row.keyValue] && (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        <Typography variant="subtitle2" align="center">
                          {row.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.title}
                        <Typography variant="subtitle2">{row?.formula}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        ${props?.providerTripDetails?.calculationDetails[row.keyValue]}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
