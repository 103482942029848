import * as Yup from 'yup';

export const DriverFormSchema = {
  programId: Yup.string().required('Select a contract.'),
  county: Yup.string().required('County is required.'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('Zip Code is required').min(5, 'Too short').max(5, 'Too long'),
  email: Yup.string().email('Enter a valid email address').required('Email is required'),
  phone: Yup.string().required('Phone Number is required').min(10, 'Too short').max(10, 'Too long'),
  licenseNumber: Yup.string().required('License Number is required'),
  minWagePerHour: Yup.string().required('Min Wage Per Hour is required'),
  vehicleType: Yup.string().required('Vehicle Type is required!'),
  vehicleYear: Yup.string().required('Vehicle Year is required!'),
  vehicleMake: Yup.string().required('Vehicle Make is required!'),
  vehicleModel: Yup.string().required('Vehicle Model is required!'),
  vehicleColor: Yup.string().required('Vehicle Color is required!'),
  vehicleState: Yup.string().required('Vehicle State is required!'),
  vehiclePlate: Yup.string().required('Vehicle Plate is required!'),
  vehicleVIN: Yup.string().required('Vehicle VIN is required!'),
  vehicleLOS: Yup.array().of(Yup.string()).min(1, 'Vehicle LOS is required!').required('Vehicle LOS is required!'),
  vehiclePower: Yup.string().required('Vehicle Power is required!'),
};
