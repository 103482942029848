import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Container, Grid, Stack, Tab, Typography } from '@mui/material';

import Page from '../../../../components/Page';
import DriverCredentialInfo from '../../../../features/driver/DriverCredentialInfo';
import { useProviderProfile } from '../../../../hooks/useProviderProfile';

export default function ProviderCredentialPage() {
  const [value, setValue] = React.useState('1');
  const { profile, isLoading, error } = useProviderProfile();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) return 'Loading...';

  if (error) return `An error has occurred: ${error.message}`;

  return (
    <Page title="Credentials">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} p={1}>
          <Typography variant="h4">Credentials Information</Typography>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Card sx={{ padding: 2 }}>
              <Grid container>
                <Grid item xs={12}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange}>
                        <Tab label="Credentials" value="1" disableRipple sx={{ padding: 2.2 }} />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Grid container>
                        <Grid item xs={12}>
                          <DriverCredentialInfo credentials={profile?.credentials} />
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
