import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import * as Yup from 'yup';

import { FormProvider, RHFTextField } from '../../components/hook-form';
import PROGRAM_ATTRIBUTES from '../../data/program-attributes';
import USStates from '../../data/us-states';

import AddAndUpdateAttributeDialog from './AddAndUpdateAttributeDialog';
import ProgramAttributeFieldList from './ProgramAttributeFieldList';

function prepareData(formData) {
  const data = {
    programName: formData?.programName,
    programState: formData?.programState,
    effectiveDate: format(formData?.effectiveDate, 'yyyy-MM-dd'),
    expiryDate: format(formData?.expiryDate, 'yyyy-MM-dd'),
  };

  return data;
}

export default function EditProgramForm(props) {
  const {
    formType,
    program,
    handleProgramAttributeAddAction,
    handleProgramAttributeUpdateAction,
    handleProgramAttributeDialogClose,
    programAttributeDialog,
    onProgramAttributeUpdateSubmit,
  } = props;

  const [programAttributeList, setProgramAttributeList] = React.useState({});

  const programSchema = {
    programName: Yup.string().required('Name is required'),
    programState: Yup.string().required('State is required'),
    effectiveDate: Yup.date().required('Effective date is required!'),
    expiryDate: Yup.date().required('Expiry date is required!'),
  };
  const defaultValues = {
    programName: '',
    programState: '',
    effectiveDate: '',
    expiryDate: '',
  };

  const methods = useForm({
    resolver: yupResolver(Yup.object().shape({ ...programSchema })),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    props?.onSubmit(prepareData(data));
  };

  React.useEffect(() => {
    if (program && 'programId' in program) {
      methods.setValue('programName', program?.programName);
      methods.setValue('programState', program?.programState);
      methods.setValue('effectiveDate', program?.effectiveDate);
      methods.setValue('expiryDate', program?.expiryDate);
      setProgramAttributeList(program?.attributeMap);
    }
  }, [program, methods]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" gutterBottom>
          Contract Information
        </Typography>

        {/* Contract section */}
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="caption" gutterBottom>
                Contract Name
              </Typography>
              <RHFTextField name="programName" label="" size="small" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="caption" gutterBottom>
                Contract State
              </Typography>
              <RHFTextField name="programState" label="" select size="small">
                {USStates.map((option) => (
                  <MenuItem key={option.abbreviation} value={option.abbreviation}>
                    {option.name}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <Stack>
                <Typography variant="caption" gutterBottom>
                  Effective Date
                </Typography>
                <RHFTextField name="effectiveDate" type="date" size="small" />
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <Stack>
                <Typography variant="caption" gutterBottom>
                  Expiry Date
                </Typography>
                <RHFTextField name="expiryDate" type="date" size="small" />
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="subtitle2" gutterBottom>
                &nbsp;&nbsp;
              </Typography>
              <LoadingButton type="submit" variant="soft" loading={isSubmitting} fullWidth startIcon={<SaveIcon />}>
                save
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              {/* Contract Attributes */}
              {PROGRAM_ATTRIBUTES.map((attributes) => (
                <ProgramAttributeFieldList
                  key={`program_attribute_${attributes.id}`}
                  name={attributes.name}
                  label={attributes.label}
                  formType={formType}
                  programId={program?.programId}
                  programAttributeList={programAttributeList[attributes.name]}
                  handleProgramAttributeAddAction={handleProgramAttributeAddAction}
                  handleProgramAttributeUpdateAction={handleProgramAttributeUpdateAction}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
      {programAttributeDialog.open && (
        <AddAndUpdateAttributeDialog
          {...programAttributeDialog}
          programId={program?.programId}
          handleClose={handleProgramAttributeDialogClose}
          onSubmit={onProgramAttributeUpdateSubmit()}
        />
      )}
    </>
  );
}
