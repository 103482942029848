import doRequest from '../../lib/api';

import { PROVIDER_PROFILE_API_URL } from './constant';

export function getProviderProfile() {
  const config = {
    url: PROVIDER_PROFILE_API_URL,
  };
  return doRequest.get(config);
}
