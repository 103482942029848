import React from 'react';
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import fileDownload from 'js-file-download';

import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import {
  getActivityByProgramId as getActivityByProgramIdService,
  getActivityPeriod as getActivityPeriodService,
} from '../../services/activity';
import { getAnalyticsReport } from '../../services/analytics';
import { getPrograms as getProgramsService } from '../../services/program';

const initialState = {
  programs: [],
  selectedProgram: {},
  selectedWeek: '',
  activityPeriod: [],
  activity: [],
  uploadActivityPeriod: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PROGRAMS':
      return { ...state, programs: action.payload };
    case 'SET_SELECTED_PROGRAMS':
      return { ...state, selectedProgram: action.payload };
    case 'SET_WEEK':
      return { ...state, selectedWeek: action.payload };
    case 'SET_ACTIVITY_PERIOD':
      return { ...state, activityPeriod: action.payload };
    case 'SET_UPLOAD_ACTIVITY_PERIOD':
      return { ...state, uploadActivityPeriod: action.payload };
    case 'SET_ACTIVITY':
      return { ...state, activity: action.payload };
    case 'RESET':
      return { ...initialState };
    default:
      return { ...state };
  }
}

export default function ActivityPage() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const getPrograms = React.useCallback(async () => {
    const { data } = await getProgramsService();
    if (data) {
      dispatch({ type: 'SET_PROGRAMS', payload: data?.programList });
    }
  }, []);

  const getActivityPeriod = React.useCallback(async ({ programId }) => {
    const { data } = await getActivityPeriodService({ programId });
    if (data) {
      dispatch({ type: 'SET_ACTIVITY_PERIOD', payload: data });
    }
  }, []);

  const getActivity = React.useCallback(
    async ({ payload }) => {
      const { data } = await getActivityByProgramIdService({
        programId: state.selectedProgram?.programId,
        data: payload,
      });
      if (data) {
        dispatch({ type: 'SET_ACTIVITY', payload: data });
      }
    },
    [state.selectedProgram?.programId]
  );

  const handleProgramChange = React.useCallback(
    (event) => {
      dispatch({ type: 'SET_SELECTED_PROGRAMS', payload: event?.target?.value });
      getActivityPeriod({ programId: event?.target?.value?.programId });
    },
    [getActivityPeriod]
  );

  React.useEffect(() => {
    getPrograms();
  }, [getPrograms]);

  const handleWeekChange = React.useCallback(
    (event) => {
      const { value } = event.target;
      dispatch({ type: 'SET_WEEK', payload: value });
      getActivity({
        payload: { fromDate: value?.fromDate, toDate: value?.toDate },
      });
    },
    [getActivity]
  );

  const reportDownloadActionHandler = React.useCallback(
    async ({ fileName, reportType, fileFormat }) => {
      const payload = {
        reportType,
        programId: state.selectedProgram?.programId,
        fromDate: state.selectedWeek?.fromDate,
        toDate: state.selectedWeek?.toDate,
        fileFormat,
      };
      const { data } = await getAnalyticsReport({ data: payload });
      if (data) {
        fileDownload(data, `${fileName}.${fileFormat.toLowerCase()}`);
      }
    },
    [state.selectedProgram, state.selectedWeek]
  );

  return (
    <>
      <Page title="Analytics">
        <Container maxWidth={false}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                  Analytics
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      id="contract-select"
                      select
                      label="Contract & State"
                      value={state.selectedProgram}
                      onChange={handleProgramChange}
                    >
                      {state.programs?.map((option) => (
                        <MenuItem key={option.programId} value={option}>
                          {`${option?.programName} - ${option?.programState}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      id="week-select"
                      select
                      label="Select Activity Period"
                      value={state.selectedWeek}
                      onChange={handleWeekChange}
                    >
                      {state.activityPeriod.map((option, index) => (
                        <MenuItem key={`week-with-date-${option?.activityPeriod}-${index}`} value={option}>
                          {option?.activityPeriod}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Card} sx={{ minHeight: 180 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Report Type</TableCell>
                      <TableCell align="center">PDF</TableCell>
                      <TableCell align="center">CSV</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2">Earnings Adjuction Report</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="text"
                          color="primary"
                          startIcon={<Iconify icon="material-symbols:download" />}
                          disabled={!state.selectedWeek}
                          onClick={(e) => {
                            e.preventDefault();
                            reportDownloadActionHandler({
                              fileName: `earnings_adjunction_report`,
                              fileFormat: 'PDF',
                              reportType: 'EAR',
                            });
                          }}
                        >
                          Download
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="text"
                          color="primary"
                          startIcon={<Iconify icon="material-symbols:download" />}
                          disabled={!state.selectedWeek}
                          onClick={(e) => {
                            e.preventDefault();
                            reportDownloadActionHandler({
                              fileName: `earnings_adjunction_report`,
                              fileFormat: 'CSV',
                              reportType: 'EAR',
                            });
                          }}
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
