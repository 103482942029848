// @mui
// form
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  onChangeHandler: PropTypes.func,
};

export default function RHFTextField({ name, onChangeHandler, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <TextField
          {...field}
          onChange={({ target: { value } }) => {
            onChange(value);
            if (onChangeHandler) {
              onChangeHandler(value);
            }
          }}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
