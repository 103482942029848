import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';

import Scrollbar from '../../components/Scrollbar';
import { fDate, getUniqueKey } from '../../utils';

import DriverListHead from './DriverListHead';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'tripDate', label: 'Trip Date', alignRight: false },
  { id: 'pickupscheudleTime', label: 'Time', alignRight: false },
  { id: 'pickupAddress', label: 'Pickup Address', alignRight: false },
  { id: 'dropAddress', label: 'Drop Address', alignRight: false },
  { id: 'pickupserviceLevel', label: 'Service Level', alignRight: false },
  { id: 'calculatedPayment', label: 'Calculated Payment', alignRight: true },
  { id: 'calculateButton', label: '', alignRight: false },
];

export default function DriverTripsTable(props) {
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState('asc');

  const [orderBy, setOrderBy] = React.useState('name');

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props?.trips.length) : 0;

  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table size="small">
            <DriverListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={props?.trips.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {props?.trips?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={getUniqueKey(`drivers_trip_table_${index}`, row?.id)}>
                  <TableCell align="left">{fDate(row?.tripDate)}</TableCell>
                  <TableCell align="left">{row?.pickupscheudleTime}</TableCell>
                  <TableCell align="left">{row?.pickupAddress}</TableCell>
                  <TableCell align="left">{row?.dropAddress}</TableCell>
                  <TableCell align="left">{row?.pickupserviceLevel}</TableCell>
                  <TableCell align="right">{`$${row?.calculatedPayment}`}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={(event) => {
                        event.preventDefault();
                        props.showCalculateTable({ id: row?.id });
                      }}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props?.trips.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
