import { Stack } from '@mui/material';

import { RHFTextField } from '../../components/hook-form';
import { FromTitle } from '../../components/Titles';

export function PersonalInformation() {
  return (
    <>
      <FromTitle title="Personal Information" />
      <Stack
        spacing={3}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        mb={3}
      >
        <RHFTextField size="small" name="firstName" label="First Name" />
        <RHFTextField size="small" name="middleName" label="Middle Name" />
        <RHFTextField size="small" name="lastName" label="Last Name" />
      </Stack>
    </>
  );
}
