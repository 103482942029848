import { Box, Divider, Stack, styled, Typography } from '@mui/material';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.secondary.light,
}));

export default function FromTitle({ title, children, gutterBottom = 1, divider }) {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={gutterBottom}>
        <Typography variant="subtitle1">{title}</Typography>
        {children}
      </Stack>
      {divider && <StyledDivider />}
    </>
  );
}
