import React from 'react';
import { IMaskInput } from 'react-imask';

const TextMask = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      {...other}
      unmask
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default TextMask;
