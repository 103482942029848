import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Button, Card, Container, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';

import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import { PageTitle } from '../../components/Titles';
import CreateProgramForm from '../../features/program/CreateProgramForm';
import { createProgram, validateProgram } from '../../services/program';

const steps = ['Add Basic Contract Details', 'Add Contract Attributes'];

export default function CreateContract() {
  const navigate = useNavigate();
  const [program, setProgram] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);

  const onProgramSubmit = async (formData) => {
    const { data } = await validateProgram(formData);
    if (data?.success) {
      setProgram(formData);
      setActiveStep(1);
    }
  };

  const onAttributeSubmit = async (data) => {
    const resp = await createProgram({ ...program, ...data });
    if (resp?.data) {
      navigate('/provado-app/contract', { replace: true });
    }
  };

  const onSubmitActionMap = {
    0: onProgramSubmit,
    1: onAttributeSubmit,
  };

  const onSubmit = () => (data) => {
    onSubmitActionMap[activeStep](data);
  };

  return (
    <>
      <Page title="Create Contract">
        <Container maxWidth="xl">
          <PageTitle title="Create Contract">
            <Button
              variant="outlined"
              component={RouterLink}
              to="/provado-app/contract"
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            >
              Contract List
            </Button>
          </PageTitle>

          <Box sx={{ width: '100%', mb: 4, p: 1 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Card sx={{ padding: 2 }}>
            <CreateProgramForm onSubmit={onSubmit()} formType="CREATE_PROGRAM" activeStep={activeStep} />
          </Card>
        </Container>
      </Page>
    </>
  );
}
