import doRequest from '../../lib/api';

import { PROGRAM_API_URL } from './constant';

export function getPrograms() {
  const config = {
    url: PROGRAM_API_URL,
  };
  return doRequest.get(config);
}

export function getProgramById(programId) {
  const config = {
    url: `${PROGRAM_API_URL}/${programId}`,
  };
  return doRequest.get(config);
}
