import doRequest from '../../lib/api';

import { DRIVER_API_URL } from './constant';

export function createDriver(driver) {
  const config = {
    url: DRIVER_API_URL,
    data: driver,
  };
  return doRequest.post(config);
}
