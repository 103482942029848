import React from 'react';
import { Card, Container, Divider, Grid, Stack, Typography } from '@mui/material';

import Page from '../../../../components/Page';
import { useProviderProfile } from '../../../../hooks/useProviderProfile';

export default function ProviderProfilePage() {
  const { profile, isLoading, error } = useProviderProfile();

  if (isLoading) return 'Loading...';

  if (error) return `An error has occurred: ${error.message}`;

  return (
    <Page title="Profile">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} p={1}>
          <Typography variant="h4">Profile Information</Typography>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Card sx={{ padding: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2} alignItems="center" mb={1} justifyContent="space-between">
                    <Typography variant="h6">About</Typography>
                  </Stack>
                  <Divider sx={{ marginBottom: 3 }} />

                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          First Name
                        </Typography>
                        <Typography variant="subtitle2">{profile?.firstName}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Middle Name
                        </Typography>
                        <Typography variant="subtitle2">{profile?.middleName}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Last Name
                        </Typography>
                        <Typography variant="subtitle2">{profile?.lastName}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Address
                        </Typography>
                        <Typography variant="subtitle2">{profile?.address}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          City
                        </Typography>
                        <Typography variant="subtitle2">{profile?.city}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          State
                        </Typography>
                        <Typography variant="subtitle2">{profile?.state}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Zip Code
                        </Typography>
                        <Typography variant="subtitle2">{profile?.zipCode}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Mobile
                        </Typography>
                        <Typography variant="subtitle2">{profile?.phone}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Email
                        </Typography>
                        <Typography variant="subtitle2">{profile?.email}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          License Number
                        </Typography>
                        <Typography variant="subtitle2">{profile?.licenseNumber}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Status
                        </Typography>
                        <Typography variant="subtitle2">{profile?.status}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Verified
                        </Typography>
                        <Typography variant="subtitle2">{profile?.isVerified ? 'Yes' : 'No'}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
