import doRequest from '../../lib/api';
import { DRIVER_API_URL } from '../driver/constant';
import { PROGRAM_API_URL } from '../program/constant';

import { ACTIVITY_API_URL } from './constant';

export function getActivityByProgramId({ programId, data }) {
  const config = {
    url: `${PROGRAM_API_URL}/${programId}${ACTIVITY_API_URL}`,
    data,
  };
  return doRequest.post(config);
}

export function getActivityById({ id }) {
  const config = {
    url: `${DRIVER_API_URL}/${id}${ACTIVITY_API_URL}`,
  };
  return doRequest.get(config);
}
