import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Button, Card, Container, Stack, Typography } from '@mui/material';

import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import { PageTitle } from '../../components/Titles';
import EditProgramForm from '../../features/program/EditProgramForm';
import { createAttribute, getProgramById, updateAttribute, updateProgram } from '../../services/program';

const defaultProgramAttributeDialogState = {
  open: false,
  name: '',
  label: '',
  title: '',
  id: '',
  state: {},
};

const AttributeAPIMap = {
  add: createAttribute,
  update: updateAttribute,
};

export default function EditProgram() {
  const { id } = useParams();

  const [program, setProgram] = React.useState({});
  const [programAttributeDialog, setProgramAttributeDialog] = React.useState({
    ...defaultProgramAttributeDialogState,
  });

  const onSubmit = () => async (data) => {
    await updateProgram(data, id);
  };

  const onProgramAttributeUpdateSubmit = () => async (data) => {
    const payload = { ...data, id: programAttributeDialog?.state?.id };
    const resp = await AttributeAPIMap[programAttributeDialog?.id.toLowerCase()](payload);
    if (resp?.data) {
      handleProgramAttributeDialogClose();
      onMountEvent(id);
    }
  };

  const handleProgramAttributeAddAction = (name, label) => {
    setProgramAttributeDialog((prevState) => ({
      ...prevState,
      name,
      label,
      id: 'ADD',
      open: true,
      title: 'Add Attribute',
      state: {},
    }));
  };

  const handleProgramAttributeUpdateAction = (name, label, selectedAttribute) => {
    setProgramAttributeDialog((prevState) => ({
      ...prevState,
      name,
      label,
      id: 'UPDATE',
      open: true,
      title: 'Update Attribute',
      state: selectedAttribute,
    }));
  };

  const handleProgramAttributeDialogClose = () => {
    setProgramAttributeDialog(defaultProgramAttributeDialogState);
  };

  const onMountEvent = async (prgId) => {
    const res = await getProgramById(prgId);
    setProgram(res?.data);
  };

  React.useEffect(() => {
    onMountEvent(id);
  }, [id]);

  return (
    <Page title="Edit Contract">
      <Container maxWidth="xl">
        <PageTitle title="Edit Contract">
          <Button
            variant="outlined"
            component={RouterLink}
            to="/provado-app/contract"
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          >
            Contract List
          </Button>
        </PageTitle>

        <Card sx={{ padding: 2 }}>
          <EditProgramForm
            onSubmit={onSubmit()}
            formType="UPDATE_PROGRAM"
            program={program}
            handleProgramAttributeAddAction={handleProgramAttributeAddAction}
            handleProgramAttributeUpdateAction={handleProgramAttributeUpdateAction}
            handleProgramAttributeDialogClose={handleProgramAttributeDialogClose}
            programAttributeDialog={programAttributeDialog}
            onProgramAttributeUpdateSubmit={onProgramAttributeUpdateSubmit}
          />
        </Card>
      </Container>
    </Page>
  );
}
