import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { lowerCase } from 'lodash';

import { fDate } from '../../utils/formatTime';

const StyledTableCell = styled(TableCell)(() => ({
  padding: `6px 10px`,
  [`&.${tableCellClasses.head}`]: {
    // background: theme.palette.grey[400],
    // color: theme.palette.grey.contrastText,
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    // border: 0,
  },
}));

export default function DriverCredentialInfo(props) {
  return (
    <TableContainer>
      <Table aria-label="providers credential table" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Typography variant="subtitle1">Name</Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography variant="subtitle1">Expiry Date</Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography variant="subtitle1">Details</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.credentials?.map((row) => (
            <StyledTableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <StyledTableCell component="th" scope="row" sx={{ width: 140 }}>
                <Typography variant="body2">{row?.name}</Typography>
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ width: 110 }}>
                <Typography variant="body2">{fDate(row?.expDate)}</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="body2" textTransform="capitalize">
                  {lowerCase(row?.description)}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
