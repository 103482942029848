import doRequest from '../../lib/api';
import { PROGRAM_API_URL } from '../program/constant';

import { ACTIVITY_API_URL, ACTIVITY_PERIOD_API_URL } from './constant';

export function getActivityPeriod({ programId }) {
  const config = {
    url: `${PROGRAM_API_URL}/${programId}${ACTIVITY_API_URL}${ACTIVITY_PERIOD_API_URL}`,
  };
  return doRequest.get(config);
}
