import React from 'react';
import { Card, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import * as changeCase from 'change-case';

import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import { getUniqueKey, isActive } from '../../utils';

import DriverListHead from './DriverListHead';
import DriverListMoreMenu from './DriverListMoreMenu';

const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'licenseNumber', label: 'License', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

export default function DriverTable(props) {
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props?.drivers.length) : 0;

  return (
    <Card>
      <Scrollbar>
        <TableContainer>
          <Table size="small">
            <DriverListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={props?.drivers.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {props?.drivers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={getUniqueKey(`drivers_table_${index}`, row?.driverId)}>
                  <TableCell align="left">{row?.firstName}</TableCell>
                  <TableCell align="left">{row?.lastName}</TableCell>
                  <TableCell align="left">{row?.email}</TableCell>
                  <TableCell align="left">{row?.phone}</TableCell>
                  <TableCell align="left">
                    {row?.licenseNumber ? changeCase.constantCase(row?.licenseNumber) : ''}
                  </TableCell>
                  <TableCell align="left">
                    <Label variant="ghost" color={isActive(row?.status) ? 'success' : 'error'}>
                      {row?.status ? changeCase.capitalCase(row?.status) : ''}
                    </Label>
                  </TableCell>

                  <TableCell align="right">
                    <DriverListMoreMenu
                      {...row}
                      viewLink={`/provado-app/driver/view/${row?.driverId}`}
                      editLink={`/provado-app/driver/edit/${row?.driverId}`}
                      toggleActive={props.handleToggleActivation}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={props?.drivers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
