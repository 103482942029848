import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { endOfYear, format } from 'date-fns';
import * as Yup from 'yup';

import { FormProvider, RHFTextField } from '../../components/hook-form';
import PROGRAM_ATTRIBUTES from '../../data/program-attributes';
import USStates from '../../data/us-states';

import ProgramAttributeFieldList from './ProgramAttributeFieldList';

function prepareProgramData(formData) {
  const data = {
    programName: formData?.programName,
    programState: formData?.programState,
    effectiveDate: format(formData?.effectiveDate, 'yyyy-MM-dd'),
    expiryDate: format(formData?.expiryDate, 'yyyy-MM-dd'),
  };

  return data;
}

function prepareAttributeData(formData) {
  const data = {
    attributes: PROGRAM_ATTRIBUTES.map((prg) => ({
      name: prg.name,
      effectiveDate: format(formData[`${prg.name}EffectiveDate`], 'yyyy-MM-dd'),
      expiryDate: format(formData[`${prg.name}ExpiryDate`], 'yyyy-MM-dd'),
      value: formData[`${prg.name}`],
    })),
  };

  return data;
}

export default function CreateProgramForm(props) {
  const { formType, activeStep } = props;
  const numberReg = /^\d+\.?\d*$/;

  const programSchema = Yup.object().shape({
    programName: Yup.string().required('Name is required'),
    programState: Yup.string().required('State is required'),
    effectiveDate: Yup.date().required('Effective date is required!'),
    expiryDate: Yup.date().required('Expiry date is required!'),
  });
  const programDefaultValues = {
    programName: '',
    programState: '',
    effectiveDate: format(new Date(), 'yyyy-MM-dd'),
    expiryDate: format(endOfYear(new Date()), 'yyyy-MM-dd'),
  };

  const programMethods = useForm({
    resolver: yupResolver(programSchema),
    defaultValues: programDefaultValues,
  });

  const {
    handleSubmit: handleProgramSubmit,
    formState: { isSubmitting: isProgramSubmitting },
  } = programMethods;

  const programAttributeSchema = {};
  const programAttributeDefaultValues = {};

  PROGRAM_ATTRIBUTES.forEach((prg) => {
    programAttributeSchema[prg.name] = Yup.string().matches(numberReg, 'Not a valid number!').required('Required!');
    programAttributeSchema[`${prg.name}EffectiveDate`] = Yup.date().required('Effective date is required!');
    programAttributeSchema[`${prg.name}ExpiryDate`] = Yup.date().required('Expiry date is required!');
    programAttributeDefaultValues[prg.name] = '';
    programAttributeDefaultValues[`${prg.name}EffectiveDate`] = format(new Date(), 'yyyy-MM-dd');
    programAttributeDefaultValues[`${prg.name}ExpiryDate`] = format(endOfYear(new Date()), 'yyyy-MM-dd');
  });

  const attributeMethods = useForm({
    resolver: yupResolver(Yup.object().shape({ ...programAttributeSchema })),
    defaultValues: programAttributeDefaultValues,
  });

  const {
    handleSubmit: handleAttributeSubmit,
    formState: { isSubmitting: isAttributeSubmitting },
  } = attributeMethods;

  const handlePrepareDataMap = {
    0: prepareProgramData,
    1: prepareAttributeData,
  };

  const onSubmit = (data) => {
    props?.onSubmit(handlePrepareDataMap[activeStep](data));
  };

  return (
    <>
      {/* Contract section */}
      {activeStep === 0 && (
        <FormProvider methods={programMethods} onSubmit={handleProgramSubmit(onSubmit)}>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="caption" gutterBottom>
                  Contract Name
                </Typography>
                <RHFTextField name="programName" label="" size="small" />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="caption" gutterBottom>
                  Contract State
                </Typography>
                <RHFTextField name="programState" label="" select size="small">
                  {USStates.map((option) => (
                    <MenuItem key={option.abbreviation} value={option.abbreviation}>
                      {option.name}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack>
                  <Typography variant="caption" gutterBottom>
                    Effective Date
                  </Typography>
                  <RHFTextField name="effectiveDate" type="date" size="small" />
                </Stack>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack>
                  <Typography variant="caption" gutterBottom>
                    Expiry Date
                  </Typography>
                  <RHFTextField name="expiryDate" type="date" size="small" />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
            <LoadingButton type="submit" variant="soft" loading={isProgramSubmitting}>
              Next
            </LoadingButton>
          </Stack>
        </FormProvider>
      )}

      {/* Contract Attribute section */}
      {activeStep === 1 && (
        <FormProvider methods={attributeMethods} onSubmit={handleAttributeSubmit(onSubmit)}>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {PROGRAM_ATTRIBUTES.map((attributes) => (
                  <ProgramAttributeFieldList
                    key={`program_attribute_${attributes.id}`}
                    name={attributes.name}
                    label={attributes.label}
                    formType={formType}
                  />
                ))}
              </Grid>
            </Grid>
          </Box>
          <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
            <LoadingButton type="submit" variant="soft" loading={isProgramSubmitting}>
              Create Contract
            </LoadingButton>
          </Stack>
        </FormProvider>
      )}
    </>
  );
}
