import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { find as _find, lowerCase as _lowerCase, snakeCase as _snakeCase } from 'lodash';
import * as Yup from 'yup';

import { FormProvider, RHFSelect, RHFTextField } from '../../components/hook-form';
import TextMask from '../../components/TextMask';
import { FromTitle } from '../../components/Titles';
import USStates from '../../data/us-states';
import { VEHICLE_LOS, VEHICLE_POWER, VEHICLE_TYPES } from '../../data/vehicle-type';
import { getCountyByState, getCredentialsByState } from '../../services/driver';
import { getPrograms } from '../../services/program';
import { fDate } from '../../utils';

import { DriverFormDefaultValues, SetDriverFormDefaultValues } from './DriverFormDefaultValues';
import { DriverFormGeneratePayload } from './DriverFormGeneratePayload';
import { DriverFormSchema } from './DriverFormSchema';
import { PersonalInformation } from './PersonalInformation';

const ZipCodeMask = React.forwardRef((props, ref) => (
  <TextMask
    name="zipCode"
    label="Zip Code"
    mask="#0000"
    definitions={{
      '#': /[1-9]/,
    }}
    inputRef={ref}
    {...props}
  />
));

export default function DriverForm(props) {
  const { driver, formType } = props;
  const {
    isLoading: programLoading,
    error: programErr,
    data: programResp,
  } = useQuery({
    queryKey: ['getPrograms'],
    queryFn: () => getPrograms(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const [driverSchema, setDriverSchema] = React.useState({ ...DriverFormSchema });

  const [showForm, setShowForm] = React.useState(false);
  const [credentials, setCredentials] = React.useState([]);
  const [county, setCounty] = React.useState([]);

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        ...driverSchema,
      })
    ),
    defaultValues: { ...DriverFormDefaultValues },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const buildCredentialsFormField = React.useCallback(
    async (cred) => {
      const dynSchema = {};

      cred.forEach((element) => {
        const credObj = _find(driver?.credentials, { name: _snakeCase(element?.credentialName) });

        dynSchema[`cred_${_snakeCase(element?.credentialName)}_date`] = Yup.date().required('Date is required');
        methods.register(`cred_${_snakeCase(element?.credentialName)}_date`);
        methods.setValue(
          `cred_${_snakeCase(element?.credentialName)}_date`,
          format(credObj?.expDate ? new Date(credObj?.expDate) : new Date(), 'yyyy-MM-dd')
        );
      });

      await setDriverSchema((prevState) => ({
        ...prevState,
        ...dynSchema,
      }));

      setShowForm(true);
    },
    [driver?.credentials, methods]
  );

  const getCredentials = React.useCallback(
    async (state) => {
      const resp = await getCredentialsByState(state);

      if (resp && resp?.data && resp.data.length > 0) {
        setCredentials(resp?.data);
        buildCredentialsFormField(resp?.data);
      }
    },
    [buildCredentialsFormField]
  );

  const getCounty = async (state) => {
    const resp = await getCountyByState(state);

    if (resp && resp?.data && resp.data.length > 0) {
      setCounty(resp?.data);
    }
  };

  const handleStateChange = () => async (state) => {
    if (state) {
      await setCredentials([]);
      await setCounty([]);
      methods.setValue('county', '');
      getCredentials(state);
      getCounty(state);
    }
  };

  const onSubmit = (data) => {
    props?.onSubmit(
      DriverFormGeneratePayload({ formData: data, credentials, programList: programResp?.data?.programList })
    );
  };

  React.useEffect(() => {
    if (driver && 'driverId' in driver) {
      setCredentials([]);
      setCounty([]);
      SetDriverFormDefaultValues({ methods, driver });
      getCredentials(driver?.state);
      getCounty(driver?.state);
    }
  }, [driver, methods, getCredentials]);

  if (programLoading) return 'Loading Programs...';
  if (programErr) return 'Error Loading Programs.';

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FromTitle title="Contract information" />

      <Grid container spacing={3} mb={3}>
        <Grid item md={4} xs={12}>
          {programResp?.data?.programList && programResp?.data?.programList.length > 0 && (
            <RHFTextField size="small" name="programId" label="Contract/State/Contract Expiry" select>
              {programResp?.data?.programList.map((option, index) => (
                <MenuItem key={`${option?.programId}_${index}`} value={option?.programState}>
                  {`${option?.programName}  (${option?.programState}) - ${fDate(option?.expiryDate)}`}
                </MenuItem>
              ))}
            </RHFTextField>
          )}
        </Grid>
      </Grid>

      <PersonalInformation />

      <FromTitle title="Address Information" />
      <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} mb={3}>
        <RHFTextField size="small" name="address" label="Address" multiline rows={2} />
      </Stack>
      <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} mb={3}>
        <RHFTextField size="small" name="city" label="City" />
        <RHFTextField size="small" name="state" label="State" select onChangeHandler={handleStateChange()}>
          {USStates.map((option) => (
            <MenuItem key={option.abbreviation} value={option.abbreviation}>
              {option.name}
            </MenuItem>
          ))}
        </RHFTextField>
        <RHFTextField size="small" name="county" label="County" select>
          {county.map((option, index) => (
            <MenuItem key={`${option}-${index}`} value={option}>
              {option}
            </MenuItem>
          ))}
        </RHFTextField>
        <RHFTextField
          size="small"
          name="zipCode"
          placeholder="Zip Code (XXXXX)"
          InputProps={{
            inputComponent: ZipCodeMask,
          }}
        />
      </Stack>
      <Typography variant="subtitle1" gutterBottom>
        Contact Information
      </Typography>
      <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} mb={3}>
        <RHFTextField size="small" name="email" label="Email" />
        <RHFTextField
          size="small"
          name="phone"
          label="Phone (100) 000-0000"
          InputProps={{
            inputComponent: TextMask,
          }}
        />
        <RHFTextField size="small" name="licenseNumber" label="License Number" />
      </Stack>

      <Typography variant="subtitle1" gutterBottom>
        Vehicle Information
      </Typography>
      <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} mb={3}>
        <RHFTextField size="small" name="vehicleType" label="Vehicle Type" select>
          {VEHICLE_TYPES.map((option, index) => (
            <MenuItem key={`${option}_${index}`} value={option}>
              {option}
            </MenuItem>
          ))}
        </RHFTextField>
        <RHFTextField size="small" name="vehicleYear" label="Vehicle Year" />
        <RHFTextField size="small" name="vehicleMake" label="Vehicle Make" />
        <RHFTextField size="small" name="vehicleModel" label="Vehicle Model" />
        <RHFTextField size="small" name="vehicleColor" label="Vehicle Color" />
      </Stack>

      <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} mb={3}>
        <RHFTextField size="small" name="vehicleState" label="Vehicle State" select>
          {USStates.map((option) => (
            <MenuItem key={`VEHICLE_STATE_${option.abbreviation}`} value={option.abbreviation}>
              {option.name}
            </MenuItem>
          ))}
        </RHFTextField>
        <RHFTextField size="small" name="vehiclePlate" label="Vehicle Plate" />
        <RHFTextField size="small" name="vehicleVIN" label="Vehicle VIN" />
        <RHFSelect name="vehicleLOS" label="Vehicle LOS" options={VEHICLE_LOS} size="small" />
        <RHFTextField size="small" name="vehiclePower" label="Vehicle Power" select>
          {VEHICLE_POWER.map((option, index) => (
            <MenuItem key={`${option}_${index}`} value={option}>
              {option}
            </MenuItem>
          ))}
        </RHFTextField>
      </Stack>

      {credentials && credentials.length > 0 && showForm && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Credentials Information
          </Typography>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} mb={3}>
            <Grid container spacing={2}>
              {credentials?.map((element) => (
                <Grid item xs={12} md={3} key={`cred_${_snakeCase(element?.credentialName)}_key`}>
                  <Stack>
                    <Typography variant="subtitle2" gutterBottom>
                      {element?.credentialName}
                    </Typography>
                    <Typography variant="caption">Expiry Date</Typography>
                    <RHFTextField size="small" name={`cred_${_snakeCase(element?.credentialName)}_date`} type="date" />
                    <Typography variant="caption" textTransform="capitalize" color="primary">
                      {_lowerCase(element?.credentialDescription)}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography variant="body2" gutterBottom>
            Is the Provider verified?
          </Typography>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} mb={3}>
            <RHFTextField size="small" name="verified" select>
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value>Yes</MenuItem>
            </RHFTextField>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="body2" gutterBottom>
            Enter Provider min wage per hour
          </Typography>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} mb={3}>
            <RHFTextField size="small" name="minWagePerHour" label="$/hour" />
          </Stack>
        </Grid>
        {formType === 'UPDATE_DRIVER' && (
          <Grid item xs={12} md={3}>
            <Typography variant="body2" gutterBottom>
              Is the Provider Active?
            </Typography>
            <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} mb={3}>
              <RHFTextField size="small" name="active" select>
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value>Yes</MenuItem>
              </RHFTextField>
            </Stack>
          </Grid>
        )}
      </Grid>

      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <LoadingButton variant="soft" type="submit" loading={isSubmitting}>
          {`${(formType === 'CREATE_DRIVER' && 'create') || (formType === 'UPDATE_DRIVER' && 'update')} provider`}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
