import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DriverTripsTable from '../driver/DriverTripsTable';

export default function ActivityDetails(props) {
  return (
    <>
      {props?.open && (
        <>
          <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="subtitle2">Activity List</Typography>
            <Button variant="outlined" onClick={props?.handleClose}>
              Back
            </Button>
          </Stack>

          <Card variant="outlined">
            <Stack spacing={2} sx={{ p: 2 }} direction="row" justifyContent="flex-start" alignItems="center">
              <Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                <Typography variant="caption">Name</Typography>
                <Typography variant="body2">{props?.trips[0]?.driverName}</Typography>
              </Stack>
            </Stack>
            <Divider />
            <DriverTripsTable trips={props?.trips} showCalculateTable={props.showCalculateTable} />
          </Card>
        </>
      )}
    </>
  );
}
