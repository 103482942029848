import doRequest from '../../lib/api';

import { FILE_UPLOAD_API_URL, TRIP_FILE_UPLOAD_API_URL } from './constant';

export function tripFileUpload({ fileName, file, programId }) {
  const config = {
    url: `${FILE_UPLOAD_API_URL}/${programId}${TRIP_FILE_UPLOAD_API_URL}`,
    fileName,
    data: file,
    file: true,
  };
  return doRequest.post(config);
}
