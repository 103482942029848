import doRequest from '../../lib/api';

import { TRIP_API_URL, TRIP_DETAILS_API_URL } from './constant';

export function getTripDetailsForProvider({ providerId }) {
  const config = {
    url: `${TRIP_API_URL}/${providerId}${TRIP_DETAILS_API_URL}`,
  };
  return doRequest.get(config);
}
