import { Divider, Grid, Stack, Typography } from '@mui/material';
import { join as _join } from 'lodash';

function ProviderVehicleInfo(props) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              Type
            </Typography>
            <Typography variant="subtitle2">{props?.vehicle_type}</Typography>
          </Stack>
          <Divider />

          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              Year
            </Typography>
            <Typography variant="subtitle2">{props?.vehicle_year}</Typography>
          </Stack>
          <Divider />

          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              Make
            </Typography>
            <Typography variant="subtitle2">{props?.vehicle_make}</Typography>
          </Stack>
          <Divider />

          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              Model
            </Typography>
            <Typography variant="subtitle2">{props?.vehicle_model}</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              Color
            </Typography>
            <Typography variant="subtitle2">{props?.vehicle_color}</Typography>
          </Stack>
          <Divider />

          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              State
            </Typography>
            <Typography variant="subtitle2">{props?.vehicle_state}</Typography>
          </Stack>
          <Divider />

          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              Plate
            </Typography>
            <Typography variant="subtitle2">{props?.vehicle_plate}</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              VIN
            </Typography>
            <Typography variant="subtitle2">{props?.vehicle_vin}</Typography>
          </Stack>
          <Divider />

          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              LOS
            </Typography>
            <Typography variant="subtitle2">{_join(props?.vehicle_los?.vehicle_los, ', ')}</Typography>
          </Stack>
          <Divider />

          <Stack direction="row" spacing={2} alignItems="center" py={1}>
            <Typography variant="caption" sx={{ minWidth: 100 }}>
              Power
            </Typography>
            <Typography variant="subtitle2">{props?.vehicle_power}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProviderVehicleInfo;
