import doRequest from '../../lib/api';

import { CREDENTIALS_API_URL } from './constant';

export function getCredentialsByState(stateCode) {
  const config = {
    url: `${CREDENTIALS_API_URL}/${stateCode}`,
  };
  return doRequest.get(config);
}
