const PROGRAM_ATTRIBUTES = [
  { id: 0, name: 'engagedTimeEarningFactor', label: 'Engaged Time Earning Factor' },
  { id: 1, name: 'engagedMiles', label: 'Engaged Mile Earnings' },
  { id: 2, name: 'tripPeriod2RefZoneMiles', label: 'Period 2 Referral Zone Engaged Miles' },
  { id: 3, name: 'tripPeriod2RefZoneMinutes', label: 'Period 2 Referral Zone Engaged Minutes' },
  { id: 4, name: 'memberLoadingMinutesForTypeA', label: 'Loading Time Engaged Minutes - A LOS' },
  { id: 5, name: 'memberLoadingMinutesForTypeADD', label: 'Loading Time Engaged Minutes - ADD LOS' },
  { id: 6, name: 'memberUnloadingMinutesForTypeA', label: 'Unloading Time Engaged Minutes - A LOS' },
  { id: 7, name: 'memberUnloadingMinutesForTypeADD', label: 'Unloading Time Engaged Minutes - ADD LOS' },
  { id: 8, name: 'problemFactor', label: 'Problem Factor' },
];

export default PROGRAM_ATTRIBUTES;
