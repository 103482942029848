import React from 'react';
import { Button, Card, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';

import Scrollbar from '../../components/Scrollbar';

import ActivityListHead from './ActivityListHead';
import ActivityMoreMenu from './ActivityMoreMenu';

const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: true },
  { id: 'phoneNumber', label: 'Phone', alignRight: true },
  { id: 'minWagePerHour', label: 'Min Wage/hr', alignRight: true },
  { id: 'actualPayment', label: 'Actual', alignRight: true },
  { id: 'guranteedPayment', label: 'Guaranteed', alignRight: true },
  { id: '' },
];

export default function ActivityTable(props) {
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props?.payment.length) : 0;

  return (
    <Card>
      <Scrollbar>
        <TableContainer>
          <Table size="small">
            <ActivityListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={props?.payment.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {props?.payment.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const {
                  driverId,
                  firstName,
                  lastName,
                  phoneNumber,
                  minWagePerHour,
                  actualPayment,
                  guranteedPayment = 'Processing',
                } = row;

                return (
                  <TableRow key={driverId}>
                    <TableCell align="left">{firstName}</TableCell>
                    <TableCell align="right">{lastName}</TableCell>
                    <TableCell align="right">{phoneNumber}</TableCell>
                    <TableCell align="right">{`$${minWagePerHour}`}</TableCell>
                    <TableCell align="right">{`$${actualPayment}`}</TableCell>
                    <TableCell align="right">{`${guranteedPayment ? `$${guranteedPayment}` : 'Processing'}`}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();
                          props.handleViewAction({ driverId });
                        }}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props?.payment.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
