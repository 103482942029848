import { Box, Divider, Stack, styled, Typography } from '@mui/material';

export default function PageTitle({ title, children }) {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">{title}</Typography>
        {children}
      </Stack>
      {/* <Divider /> */}
      <Box pb={3} />
    </>
  );
}
