import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Button, Card, Container, Stack, Typography } from '@mui/material';

import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import { PageTitle } from '../../components/Titles';
import DriverForm from '../../features/driver/DriverForm';
import { getDriverById, updateDriver } from '../../services/driver';

export default function EditDriver() {
  const { id } = useParams();

  const [driver, setDriver] = React.useState({});

  const onSubmit = () => async (data) => {
    await updateDriver(data, id);
  };

  const onMountEvent = React.useCallback(async () => {
    const res = await getDriverById(id);
    setDriver(res?.data);
  }, [id]);

  React.useEffect(() => {
    onMountEvent();
  }, [onMountEvent]);

  return (
    <Page title="Edit Driver">
      <Container maxWidth="xl">
        <PageTitle title="Edit Provider">
          <Button
            variant="outlined"
            component={RouterLink}
            to="/provado-app/driver"
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          >
            Provider List
          </Button>
        </PageTitle>

        <Card sx={{ padding: 2 }}>
          <DriverForm onSubmit={onSubmit()} formType="UPDATE_DRIVER" driver={driver} />
        </Card>
      </Container>
    </Page>
  );
}
