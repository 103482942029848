import { upperCase as _upperCase } from 'lodash';

import { isActive } from '../../utils';

export const DriverFormDefaultValues = {
  programId: '',
  county: '',
  firstName: '',
  lastName: '',
  middleName: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  email: '',
  phone: '',
  verified: true,
  active: true,
  licenseNumber: '',
  minWagePerHour: '',
  vehicleType: '',
  vehicleYear: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleColor: '',
  vehicleState: '',
  vehiclePlate: '',
  vehicleVIN: '',
  vehicleLOS: [],
  vehiclePower: '',
};

export function SetDriverFormDefaultValues({ methods, driver }) {
  methods.setValue('programId', driver?.programState); // NOTE:- This is hard coded to state as we dont have the same id from BE
  methods.setValue('county', driver?.county);
  methods.setValue('firstName', driver?.firstName);
  methods.setValue('middleName', driver?.middleName);
  methods.setValue('lastName', driver?.lastName);
  methods.setValue('address', driver?.address);
  methods.setValue('city', driver?.city);
  methods.setValue('country', driver?.country);
  methods.setValue('email', driver?.email);
  methods.setValue('licenseNumber', driver?.licenseNumber);
  methods.setValue('phone', driver?.phone);
  methods.setValue('verified', driver?.verified);
  methods.setValue('minWagePerHour', driver?.minWagePerHour);
  methods.setValue('state', _upperCase(driver?.state));
  methods.setValue('zipCode', driver?.zipCode);
  methods.setValue('active', isActive(driver?.status));
  methods.setValue('vehicleType', driver?.vehicle_type);
  methods.setValue('vehicleYear', driver?.vehicle_year);
  methods.setValue('vehicleMake', driver?.vehicle_make);
  methods.setValue('vehicleModel', driver?.vehicle_model);
  methods.setValue('vehicleColor', driver?.vehicle_color);
  methods.setValue('vehicleState', driver?.vehicle_state);
  methods.setValue('vehiclePlate', driver?.vehicle_plate);
  methods.setValue('vehicleVIN', driver?.vehicle_vin);
  methods.setValue('vehicleLOS', driver?.vehicle_los?.vehicle_los);
  methods.setValue('vehiclePower', driver?.vehicle_power);
}
