import doRequest from '../../lib/api';

import { PROGRAM_API_URL } from './constant';

export function updateProgram(program, programId) {
  const config = {
    url: `${PROGRAM_API_URL}/${programId}`,
    data: program,
  };
  return doRequest.put(config);
}
