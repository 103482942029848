export const VEHICLE_TYPES = [
  'Sedan',
  'Coupe',
  'Truck',
  'SUV',
  'Wheelchair Van',
  'Stretcher',
  'Ambulette',
  'Ambulance',
  'Bariatric',
];

export const VEHICLE_LOS = ['Ambulatory', 'Ambulatory Door-to-Door', 'Wheelchair'];

export const VEHICLE_POWER = ['Fuel Only', 'Hybrid', 'Electric'];
