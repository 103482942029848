import React from 'react';
import { Stack, Typography } from '@mui/material';

import Label from '../../components/Label';

import DriverPaymentTable from './DriverPaymentTable';

export default function DriverPaymentInfo(props) {
  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        <Typography variant="subtitle2">Min Wage Per Hour</Typography>
        <Label variant="ghost" color="primary" sx={{ fontSize: 18, lineHeight: 24 }}>
          ${props?.minWagePerHour}
        </Label>
      </Stack>

      <DriverPaymentTable payment={props?.payment} />
    </>
  );
}
