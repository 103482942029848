import { format, formatDistanceToNow, isValid } from 'date-fns';

// ----------------------------------------------------------------------

const DATE_FORMAT = 'MM/dd/yyyy';

export function fDateTime(date) {
  return format(new Date(date), `${DATE_FORMAT} HH:mm`);
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), `${DATE_FORMAT} hh:mm p`);
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fDate(dateString, dateFormat) {
  let newDateString = '';
  const tempDateObj = new Date(dateString);

  try {
    newDateString = isValid(tempDateObj) ? format(new Date(dateString), dateFormat || DATE_FORMAT) : '';
  } catch (err) {
    console.log('🚀 ~ file: fDate.js ~ line 11 ~ fDate ~ err', err);
  }
  return newDateString;
}
