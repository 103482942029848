import { faker } from '@faker-js/faker';
import { add, endOfWeek, sub } from 'date-fns';

import { fDate } from '../utils/formatTime';

// ----------------------------------------------------------------------

const endOfWeekDate = endOfWeek(new Date(), { weekStartsOn: 1 });
const ACTIVITY_PERIOD = [...Array(12)].map((_, index) => ({
  id: index,
  uid: faker.datatype.uuid(),
  fromDate: `${fDate(add(sub(endOfWeekDate, { weeks: index + 1 }), { days: 1 }))}`,
  toDate: `${fDate(sub(endOfWeekDate, { weeks: index }))}`,
  value: `${fDate(add(sub(endOfWeekDate, { weeks: index + 1 }), { days: 1 }))} - ${fDate(
    sub(endOfWeekDate, { weeks: index })
  )}`,
}));

export default ACTIVITY_PERIOD;
