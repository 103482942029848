import React from 'react';
import { Card, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { join as _join } from 'lodash';

import Page from '../../../../components/Page';
import { useProviderProfile } from '../../../../hooks/useProviderProfile';

export default function ProviderVehiclePage() {
  const { profile, isLoading, error } = useProviderProfile();

  if (isLoading) return 'Loading...';

  if (error) return `An error has occurred: ${error.message}`;
  return (
    <Page title="Vehicle">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} p={1}>
          <Typography variant="h4">Vehicle Information</Typography>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Card sx={{ padding: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Type
                        </Typography>
                        <Typography variant="subtitle2">{profile?.vehicle_type}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Year
                        </Typography>
                        <Typography variant="subtitle2">{profile?.vehicle_year}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Make
                        </Typography>
                        <Typography variant="subtitle2">{profile?.vehicle_make}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Model
                        </Typography>
                        <Typography variant="subtitle2">{profile?.vehicle_model}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Color
                        </Typography>
                        <Typography variant="subtitle2">{profile?.vehicle_color}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          State
                        </Typography>
                        <Typography variant="subtitle2">{profile?.vehicle_state}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Plate
                        </Typography>
                        <Typography variant="subtitle2">{profile?.vehicle_plate}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          VIN
                        </Typography>
                        <Typography variant="subtitle2">{profile?.vehicle_vin}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          LOS
                        </Typography>
                        <Typography variant="subtitle2">{_join(profile?.vehicle_los?.vehicle_los, ', ')}</Typography>
                      </Stack>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2} alignItems="center" py={1}>
                        <Typography variant="caption" sx={{ minWidth: 110 }}>
                          Power
                        </Typography>
                        <Typography variant="subtitle2">{profile?.vehicle_power}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
