export const httpStatusCodeMapToVariant = ({ statusCode }) => {
  if (statusCode) {
    if (statusCode >= 200 && statusCode < 300) {
      return 'success';
    }
    if (statusCode >= 300 && statusCode < 400) {
      return 'info';
    }
    if (statusCode >= 400 && statusCode < 500) {
      return 'error';
    }
    if (statusCode >= 500) {
      return 'warning';
    }
  }
  return 'success';
};
