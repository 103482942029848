// @mui
// form
import { Controller,useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export function RHFCheckbox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func,
};

export function RHFSwitch({ name, onChangeHandler, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <Switch
              focusVisibleClassName=".Mui-focusVisible"
              disableRipple
              sx={{ m: 1 }}
              {...field}
              onChange={({ target: { value } }) => {
                onChange(value);
                if (onChangeHandler) {
                  onChangeHandler(value);
                }
              }}
              checked={field.value}
            />
          )}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFMultiCheckbox({ name, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) =>
          field.value.includes(option) ? field.value.filter((value) => value !== option) : [...field.value, option];

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(onSelected(option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
