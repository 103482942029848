import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function ConfirmationModal({
  title,
  children,
  isOpen,
  onConfirm,
  onCancel,
  cancelBtnText = 'Cancel',
  confirmBtnText = 'Okay',
}) {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const handleClose = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };
  const handleConfirm = () => {
    setOpen(false);
    if (onConfirm) {
      onConfirm();
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          {cancelBtnText}
        </Button>
        <Button size="small" color="primary" variant="contained" onClick={handleConfirm}>
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;
