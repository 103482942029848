import React from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useSnackbar } from 'notistack';

import Router from './routes';

const channel = new BroadcastChannel('APINotification');

function App() {
  const { enqueueSnackbar } = useSnackbar();

  channel.onmessage = (msg) => {
    const msgRec = JSON.parse(msg);
    enqueueSnackbar(msgRec.msg, { variant: msgRec.variant });
  };

  return (
    <>
      <Router />
    </>
  );
}

export default App;
