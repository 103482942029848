// material
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Container, Stack, Typography } from '@mui/material';

import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import { PageTitle } from '../../components/Titles';
import ProgramTable from '../../features/program/ProgramTable';
import { getPrograms as getProgramsService } from '../../services/program';

export default function Program() {
  const [programs, setPrograms] = React.useState([]);

  const getPrograms = () => {
    getProgramsService().then((res) => {
      setPrograms(res?.data?.programList);
    });
  };

  React.useEffect(() => {
    getPrograms();
  }, []);

  return (
    <Page title="Contracts">
      <Container maxWidth="xl">
        <PageTitle title="Contracts">
          <Button
            variant="outlined"
            component={RouterLink}
            to="/provado-app/contract/create"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add New Contract
          </Button>
        </PageTitle>

        <ProgramTable programs={programs} />
      </Container>
    </Page>
  );
}
