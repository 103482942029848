/* eslint-disable no-unused-vars */
import { alpha } from '@mui/material/styles';

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS

const BLUE1 = {
  50: '#e0f7fa',
  100: '#b3ebf2',
  200: '#80deea',
  300: '#4dd0e1',
  400: '#26c6da',
  500: '#00bcd4',
  600: '#00b6cf',
  700: '#00adc9',
  800: '#00a5c3',
  900: '#0097b9',
  A100: '#e2f9ff',
  A200: '#afeeff',
  A400: '#7ce3ff',
  A700: '#63ddff',
  contrastDefaultColor: '#000000',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  '500_8': alpha('#919EAB', 0.08),
  '500_12': alpha('#919EAB', 0.12),
  '500_16': alpha('#919EAB', 0.16),
  '500_24': alpha('#919EAB', 0.24),
  '500_32': alpha('#919EAB', 0.32),
  '500_48': alpha('#919EAB', 0.48),
  '500_56': alpha('#919EAB', 0.56),
  '500_80': alpha('#919EAB', 0.8),
};

export const BLUE = {
  50: '#e3f0f3',
  100: '#b9d9e0',
  200: '#8abfcc',
  300: '#5ba5b8',
  400: '#3792a8',
  500: '#147f99',
  600: '#127791',
  700: '#0e6c86',
  800: '#0b627c',
  900: '#064f6b',
  A100: '#9be0ff',
  A200: '#68d0ff',
  A400: '#35c0ff',
  A700: '#1bb8ff',
  contrastDefaultColor: '#ffffff',
};

const INDIGO = {
  50: '#e5e7ea',
  100: '#bec2cb',
  200: '#939aa8',
  300: '#677285',
  400: '#47536b',
  500: '#263551',
  600: '#22304a',
  700: '#1c2840',
  800: '#172237',
  900: '#0d1627',
  A100: '#6694ff',
  A200: '#3370ff',
  A400: '#004cff',
  A700: '#0045e6',
  contrastDefaultColor: '#ffffff',
};

export const RED = {
  50: '#fae3e1',
  100: '#f3b9b4',
  200: '#eb8b82',
  300: '#e25d50',
  400: '#dc3a2b',
  500: '#d61705',
  600: '#d11404',
  700: '#cc1104',
  800: '#c60d03',
  900: '#bc0701',
  A100: '#ffe5e5',
  A200: '#ffb2b2',
  A400: '#ff807f',
  A700: '#ff6765',
  contrastDefaultColor: '#ffffff',
};

const LIGHT_BLUE = {
  50: '#f6fafc',
  100: '#e9f3f7',
  200: '#daebf2',
  300: '#cbe3ec',
  400: '#bfdde8',
  500: '#b4d7e4',
  600: '#add3e1',
  700: '#a4cddd',
  800: '#9cc7d9',
  900: '#8cbed1',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#e5f8ff',
  contrastDefaultColor: '#000000',
};

const NEW_COLOR_OPTION = {
  50: '#f1f7fa',
  100: '#dceaf2',
  200: '#c5dde9',
  300: '#adcfe0',
  400: '#9cc4da',
  500: '#8abad3',
  600: '#82b3ce',
  700: '#77abc8',
  800: '#6da3c2',
  900: '#5a94b7',
  A100: '#ffffff',
  A200: '#ecf8ff',
  A400: '#b9e4ff',
  A700: '#a0daff',
  contrastDefaultColor: '#00000',
};

const NEW_COLOR_OPTION1 = {
  50: '#fff7fa',
  100: '#ffebf4',
  200: '#ffdeec',
  300: '#ffd0e4',
  400: '#ffc6df',
  500: '#ffbcd9',
  600: '#ffb6d5',
  700: '#ffadcf',
  800: '#ffa5ca',
  900: '#ff97c0',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
  contrastDefaultColor: '#00000',
};

const NEW_COLOR_OPTION2 = {
  50: '#fffefe',
  100: '#fefcfc',
  200: '#fefbfa',
  300: '#fdf9f8',
  400: '#fcf7f7',
  500: '#fcf6f5',
  600: '#fcf5f4',
  700: '#fbf3f2',
  800: '#fbf2f0',
  900: '#faefee',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
  contrastDefaultColor: '#00000',
};

const PRIMARY = {
  lighter: BLUE[300],
  light: BLUE[400],
  main: BLUE[500],
  dark: BLUE[600],
  darker: BLUE[700],
  contrastText: BLUE.contrastDefaultColor,
};

const SECONDARY = {
  lighter: '#C1E5FF',
  light: '#B2DAFF',
  main: '#99CAFF',
  dark: '#6F9EDB',
  darker: '#4D76B7',
  contrastText: '#fff',
};

const TERTIARY = {
  lighter: '#619EBD',
  light: '#305E7B',
  main: '#071724',
  dark: '#05111E',
  darker: '#030D19',
  contrastText: '#fff',
};

const QUATERNARY = {
  lighter: '#E0F5FF',
  light: '#D8F0FF',
  main: '#CCE9FF',
  dark: '#95B9DB',
  darker: '#668BB7',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#8CD972',
  light: '#5CB449',
  main: '#25821B',
  dark: '#156F13',
  darker: '#0D5D12',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#5BD2E3',
  light: '#32ABC9',
  main: '#0079A5',
  dark: '#005E8D',
  darker: '#004676',
  contrastText: '#fff',
};

const WARNING = {
  lighter: '#E9C05E',
  light: '#D39F35',
  main: '#B77401',
  dark: '#9D5D00',
  darker: '#834900',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#DD666D',
  light: '#BB3D51',
  main: '#8E0F2F',
  dark: '#7A0A31',
  darker: '#660731',
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  secondary: createGradient(SECONDARY.light, SECONDARY.main),
  tertiary: createGradient(TERTIARY.light, TERTIARY.main),
  quaternary: createGradient(QUATERNARY.light, QUATERNARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  tertiary: { ...TERTIARY },
  quaternary: { ...QUATERNARY },
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  red: RED,
  blue: BLUE,
  indigo: INDIGO,
  gradients: GRADIENTS,
  divider: alpha(GREY[500], 0.24),
  chart: CHART_COLORS,
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return themeMode === 'light' ? light : dark;
}
