import axios from 'axios';
import { BroadcastChannel } from 'broadcast-channel';
import * as store from 'store';

import { filterPayload as filterPayloadData, getQueryStringParams, httpStatusCodeMapToVariant } from '../../utils';

import { API_TIMEOUT, BASE_API_URL } from './constant';

const APINotificationChannel = new BroadcastChannel('APINotification');
const APILoaderIndicatorFlagChannel = new BroadcastChannel('APILoaderIndicatorFlag');

function BroadcastNotification({ msg, status }) {
  APINotificationChannel.postMessage(
    JSON.stringify({
      msg,
      variant: httpStatusCodeMapToVariant({ statusCode: status }),
    })
  );
}

function BroadcastLoadingFlag({ flag }) {
  APILoaderIndicatorFlagChannel.postMessage(
    JSON.stringify({
      loader: flag,
    })
  );
}

function getErrorMsg({ error }) {
  if (error?.response?.data?.violations && error?.response?.data?.violations.length > 0) {
    return error?.response?.data?.violations[0]?.message;
  }
  if (error?.response?.data?.errorList && error?.response?.data?.errorList.length > 0) {
    return error?.response?.data?.errorList[0]?.message;
  }
  if (error?.message) {
    return error?.message;
  }

  return 'Failed!';
}

function AuthHandler({ response, authObject }) {
  const token = response?.headers?.authorization || response?.headers?.Authorization;
  const status = response?.status;

  if (response) {
    if (token && !authObject?.authenticated) {
      store.set('user', { ...authObject, token });
    } else if (authObject?.authenticated && (status === 403 || status === 401)) {
      store.clearAll();
      window.location.reload();
    }
  }
}

async function request(params) {
  const authObject = store.get('user');
  try {
    const {
      headers = {
        Authorization: '',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      baseURL = BASE_API_URL,
      timeout = API_TIMEOUT,
      method = 'GET',
      file,
      filterPayload,
      fileName,
      formData,
      responseType,
    } = params;

    BroadcastLoadingFlag({ flag: true });

    let { qs = '', data, url = '' } = params;
    const fileFormData = new FormData();
    const payload = filterPayload ? filterPayloadData(data) : data;

    headers.Authorization = authObject?.token;

    if (file) {
      fileFormData.append('file', payload, fileName);
    }

    if (['GET'].indexOf(method) > -1 && payload) qs = `?${getQueryStringParams(payload)}`;
    else if (file) data = fileFormData;
    else if (formData) data = payload;
    else data = JSON.stringify(payload); // POST PUT DELETE
    url += qs;

    const response = await axios({
      method,
      url,
      headers,
      data,
      baseURL,
      timeout,
      responseType,
      withCredentials: true,
    });
    AuthHandler({ response, authObject });
    if (response?.data?.statusTO?.message || response?.data?.message) {
      BroadcastNotification({
        msg: response?.data?.statusTO?.message || response?.data?.message,
        status: response?.data?.success || response?.data?.statusTO?.success ? response?.status : 400,
      });
    }

    BroadcastLoadingFlag({ flag: false });

    return response;
  } catch (e) {
    console.log('🚀 ~ file: index.js ~ line 76 ~ request ~ e', e);

    AuthHandler({ response: e?.response, authObject });
    BroadcastNotification({
      msg: getErrorMsg({ error: e }),
      status: e?.response?.status || 400,
    });

    BroadcastLoadingFlag({ flag: false });

    return e;
  }
}

export default {
  get: (params) => request({ method: 'GET', ...params }),
  post: (params) => request({ method: 'POST', ...params }),
  put: (params) => request({ method: 'PUT', ...params }),
  patch: (params) => request({ method: 'PATCH', ...params }),
  delete: (params) => request({ method: 'DELETE', ...params }),
};
