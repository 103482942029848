import { Link as RouterLink } from 'react-router-dom';
import { Container, Link, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import BgImage from '../assets/bg_report-concern.jpeg';
import Page from '../components/Page';
import ChangePasswordForm from '../features/auth/ChangePasswordForm';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const theme = useTheme();
  return (
    <Page
      title="ChangePassword"
      sx={{
        position: 'relative',
        '&:before': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          width: '100%',
          height: '100%',
          [theme.breakpoints.up('sm')]: {
            background: `url(${BgImage}) no-repeat center center`,
            opacity: 0.75,
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center top',
            backgroundSize: 'cover',
          },
        },
      }}
    >
      <RootStyle>
        <Container>
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Change your password.
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }} />

            <ChangePasswordForm />

            <Typography variant="body2" sx={{ mt: { md: 2 } }}>
              Already have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/login">
                Login
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
