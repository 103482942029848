import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Card, Container, Grid, Stack, Tab, Typography } from '@mui/material';

import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import { PageTitle } from '../../components/Titles';
import DriverCredentialInfo from '../../features/driver/DriverCredentialInfo';
import DriverPaymentInfo from '../../features/driver/DriverPaymentInfo';
import DriverPersonalInfo from '../../features/driver/DriverPersonalInfo';
import ProviderVehicleInfo from '../../features/driver/ProviderVehicleInfo';
import { useProviderActivity } from '../../hooks/useProviderActivity';
import { activateDeactivateDriver, getDriverById } from '../../services/driver';
import { isActive } from '../../utils';

export default function ViewDriver() {
  const { id } = useParams();

  const [value, setValue] = React.useState('1');
  const [driver, setDriver] = React.useState({});
  const [active, setActive] = React.useState(false);

  const { activity, isLoading } = useProviderActivity({ id });

  const getDriverDetails = React.useCallback(() => {
    getDriverById(id).then((resp) => {
      setDriver(resp?.data);
      setActive(isActive(resp?.data?.status));
    });
  }, [id]);

  const updateDriverStatus = (driverId, status) => {
    activateDeactivateDriver(driverId, status).then(() => {
      // getDriverDetails();
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleActiveChange = () => (event, newValue) => {
    setActive(newValue);
    updateDriverStatus(driver?.driverId, newValue);
  };

  React.useEffect(() => {
    getDriverDetails();
  }, [getDriverDetails, id]);

  return (
    <Page title="View Provider">
      <Container maxWidth="xl">
        <PageTitle title="Provider Information">
          <Button
            variant="outlined"
            color="primary"
            component={RouterLink}
            to="/provado-app/driver"
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          >
            Provider List
          </Button>
        </PageTitle>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DriverPersonalInfo {...driver} handleActiveChange={handleActiveChange} active={active} />
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ padding: 2 }}>
              <Grid container>
                <Grid item xs={12}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange}>
                        <Tab label="Earnings" value="1" disableRipple sx={{ padding: 2.2 }} />
                        <Tab label="Credentials" value="2" disableRipple />
                        <Tab label="Vehicle" value="3" disableRipple />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ px: 0 }}>
                      {isLoading ? 'Loading...' : <DriverPaymentInfo {...driver} payment={activity} />}
                    </TabPanel>
                    <TabPanel value="2" sx={{ px: 0 }}>
                      <DriverCredentialInfo {...driver} />
                    </TabPanel>
                    <TabPanel value="3" sx={{ px: 0 }}>
                      <ProviderVehicleInfo {...driver} />
                    </TabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
