import doRequest from '../../lib/api';

import { ACTIVITY_API_URL, DRIVER_API_URL, TRIPS_API_URL } from './constant';

export function getTripsByProviderId({ providerId, fromDate, toDate }) {
  const config = {
    url: `${DRIVER_API_URL}/${providerId}${ACTIVITY_API_URL}${TRIPS_API_URL}`,
    data: { fromDate, toDate },
  };
  return doRequest.post(config);
}
