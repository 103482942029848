import React from 'react';
import { AppBar, Box, IconButton, LinearProgress, Stack, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { BroadcastChannel } from 'broadcast-channel';
import PropTypes from 'prop-types';

import Iconify from '../../components/Iconify';

import AccountPopover from './AccountPopover';
import { APP_BAR_DESKTOP, APP_BAR_MOBILE, DRAWER_WIDTH } from './config';

const channel = new BroadcastChannel('APILoaderIndicatorFlag');

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APP_BAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

ProvadoAppNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function ProvadoAppNavbar({ onOpenSidebar }) {
  const [loading, setLoading] = React.useState(false);

  channel.onmessage = (msg) => {
    const msgRec = JSON.parse(msg);
    setLoading(msgRec.loader);
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            onOpenSidebar();
          }}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
      {loading && <LinearProgress />}
    </RootStyle>
  );
}
