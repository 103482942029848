import doRequest from '../../lib/api';

import { ANALYTICS_API_URL, REPORT_API_URL } from './constant';

export function getAnalyticsReport({ data }) {
  const config = {
    url: `${ANALYTICS_API_URL}${REPORT_API_URL}`,
    responseType: 'blob',
    data,
  };
  return doRequest.post(config);
}
