import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '60px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export function FileDropZone({ maxFiles, multiple, accept = { 'image/*': [] }, onFileSelected = () => {} }) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    accept,
    multiple,
    maxFiles,
  });
  const [myFiles, setMyFiles] = React.useState([]);

  const filesListComponent = myFiles.map((f) => (
    <ListItem key={f.name} dense>
      <ListItemText primary={f.name} />
    </ListItem>
  ));

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  React.useEffect(() => {
    setMyFiles([...myFiles, ...acceptedFiles]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  React.useEffect(() => {
    onFileSelected(myFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myFiles]);

  return (
    <Box className="container">
      <Stack {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography variant="body2">Drag 'n' drop some files here, or click to select files</Typography>
      </Stack>
      <Box component="aside">
        <List>{filesListComponent}</List>
      </Box>
    </Box>
  );
}
