import doRequest from '../../lib/api';

import { EARNING_FILE_UPLOAD_API_URL, FILE_UPLOAD_API_URL } from './constant';

export function earningFileUpload({ fileName, file, fromDate, toDate, override, programId }) {
  const fileFormData = new FormData();
  fileFormData.append('file', file, fileName);
  fileFormData.append(
    'data',
    JSON.stringify({
      fromDate,
      toDate,
      override,
    })
  );

  const config = {
    url: `${FILE_UPLOAD_API_URL}/${programId}${EARNING_FILE_UPLOAD_API_URL}`,
    fileName,
    data: fileFormData,
    formData: true,
  };
  return doRequest.post(config);
}
