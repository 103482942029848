import React from 'react';
import { Card, Divider, FormControlLabel, Grid, Stack, Switch, Typography } from '@mui/material';

import Label from '../../components/Label';
import { ShowTitleAndValue } from '../../components/ShowTitleAndValue';
import { isActive } from '../../utils';

export default function DriverPersonalInfo(props) {
  return (
    <Card sx={{ padding: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center" mb={1} justifyContent="space-between">
            <Typography variant="h6">About</Typography>
            <FormControlLabel
              control={
                <Switch
                  onChange={props.handleActiveChange()}
                  focusVisibleClassName=".Mui-focusVisible"
                  disableRipple
                  sx={{ m: 1 }}
                  checked={props?.active}
                />
              }
              label=""
            />
          </Stack>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ShowTitleAndValue title="First Name" value={props?.firstName} space={80} />
              <ShowTitleAndValue title="Middle Name" value={props?.middleName} space={80} />
              <ShowTitleAndValue title="Last Name" value={props?.lastName} space={80} />
              <ShowTitleAndValue
                title="Status"
                value={
                  <Label variant="ghost" color={isActive(props?.status) ? 'success' : 'error'}>
                    {props?.status}
                  </Label>
                }
                space={80}
              />
              <ShowTitleAndValue
                title="Verified"
                value={
                  <Label variant="ghost" color={props?.isVerified ? 'success' : 'error'}>
                    {props?.isVerified ? 'Yes' : 'No'}
                  </Label>
                }
                space={80}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <ShowTitleAndValue title="Address" value={props?.address} space={50} />
              <ShowTitleAndValue title="City" value={props?.city} space={50} />
              <ShowTitleAndValue title="State" value={props?.state} space={50} />
              <ShowTitleAndValue title="Zip Code" value={props?.zipCode} space={50} />
            </Grid>

            <Grid item xs={12} md={4}>
              <ShowTitleAndValue title="Mobile" value={props?.phone} space={50} />
              <ShowTitleAndValue title="Email" value={props?.email} space={50} />
              <ShowTitleAndValue title="License" value={props?.licenseNumber} space={50} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
