import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Container, Grid, Stack, Tab, Typography } from '@mui/material';
import fileDownload from 'js-file-download';

import Page from '../../../../components/Page';
import DriverPaymentTable from '../../../../features/driver/DriverPaymentTable';
import { useProviderActivity } from '../../../../hooks/useProviderActivity';
import { getActivityDownloadById } from '../../../../services/activity';

export default function ProviderPaymentPage() {
  const [value, setValue] = React.useState('1');

  const { activity, isLoading } = useProviderActivity({ id: 'self' });

  const handleChange = React.useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const handleActivityDownloadByPeriod = React.useCallback(async ({ fromDate, toDate }) => {
    const { data } = await getActivityDownloadById({ id: 'self', fromDate, toDate });
    if (data) {
      fileDownload(data, 'activity_details.pdf');
    }
  }, []);

  if (isLoading) return 'Loading...';

  return (
    <Page title="Activity">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} p={1}>
          <Typography variant="h4">Activity Information</Typography>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Card sx={{ padding: 2 }}>
              <Grid container>
                <Grid item xs={12}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={() => {
                          handleChange();
                        }}
                      >
                        <Tab label="Activity" value="1" disableRipple sx={{ padding: 2.2 }} />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <DriverPaymentTable
                        payment={activity}
                        handleActivityDownloadAction={handleActivityDownloadByPeriod}
                      />
                    </TabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
