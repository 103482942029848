import React from 'react';
import { Card, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';

import Scrollbar from '../../components/Scrollbar';
import { fDate } from '../../utils/formatTime';

import ProgramListHead from './ProgramListHead';
import ProgramListMoreMenu from './ProgramListMoreMenu';

const TABLE_HEAD = [
  { id: 'programName', label: 'Name', alignRight: false },
  { id: 'programState', label: 'State', alignRight: false },
  { id: 'effectiveDate', label: 'Effective Date', alignRight: false },
  { id: 'expiryDate', label: 'Expiry Date', alignRight: false },
  { id: '' },
];

export default function ProgramTable(props) {
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props?.programs.length) : 0;

  return (
    <Card>
      <Scrollbar>
        <TableContainer>
          <Table size="small">
            <ProgramListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={props?.programs.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {props?.programs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { effectiveDate, expiryDate, programId, programName, programState } = row;

                return (
                  <TableRow key={programId}>
                    <TableCell align="left">{programName}</TableCell>
                    <TableCell align="left">{programState}</TableCell>
                    <TableCell align="left">{fDate(effectiveDate)}</TableCell>
                    <TableCell align="left">{fDate(expiryDate)}</TableCell>

                    <TableCell align="right">
                      <ProgramListMoreMenu
                        viewLink={`/provado-app/contract/view/${row?.programId}`}
                        editLink={`/provado-app/contract/edit/${row?.programId}`}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props?.programs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
