import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeHandler: PropTypes.func,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RHFSelect({ name, label, onChangeHandler, options, ...other }) {
  const { control, setValue } = useFormContext();

  const handleChange = (value) =>
    // On autofill we get a stringified value.
    typeof value === 'string' ? value.split(',') : value;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, ...field }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel id={`${name}-multiple-checkbox-label`} error={!!error} size="small">
            {label}
          </InputLabel>
          <Select
            {...field}
            labelId={`${name}-multiple-checkbox-label`}
            id={`${name}-multiple-checkbox`}
            multiple
            value={field.value ? field.value : []}
            onChange={({ target: { value } }) => {
              const newVal = handleChange(value);
              onChange(newVal);
              setValue(name, newVal);
              if (onChangeHandler) {
                onChangeHandler(newVal);
              }
            }}
            onBlur={onBlur}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            error={!!error}
            {...other}
          >
            {options.map((name, index) => (
              <MenuItem key={`${name}_${index}`} value={name}>
                <Checkbox checked={field.value.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
          {error?.message && <FormHelperText error={!!error}>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
