import doRequest from '../../lib/api';

import { ATTRIBUTE_API_URL } from './constant';

export function updateAttribute(attribute) {
  const config = {
    url: ATTRIBUTE_API_URL,
    data: attribute,
  };
  return doRequest.put(config);
}
