import React from 'react';
import { alpha, Stack, styled, Typography } from '@mui/material';

const StyledContainer = styled(Stack)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export function ShowTitleAndValue(props) {
  const { title, value, space } = props;
  return (
    <StyledContainer direction="row" spacing={2} alignItems="center">
      <Typography variant="caption" sx={{ minWidth: space }}>
        {title}
      </Typography>
      <Typography variant="subtitle2">{value}</Typography>
    </StyledContainer>
  );
}
