import NoteAltOutlined from '@mui/icons-material/NoteAltOutlined';
import { Button, Grid, Stack, Typography } from '@mui/material';

import { RHFTextField } from '../../components/hook-form';

function getInputProps(props, fieldName) {
  if (props?.formType === 'UPDATE_PROGRAM') {
    return {
      readOnly: props?.formType === 'UPDATE_PROGRAM',
      value: props[fieldName],
    };
  }

  return {};
}

function ProgramAttributeFieldItem(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={props.fieldsize || 3}>
        <Stack>
          <Typography variant="caption" gutterBottom>
            Effective Date
          </Typography>
          <RHFTextField
            size="small"
            type="date"
            name={`${props.name}EffectiveDate`}
            InputProps={getInputProps(props, 'effectiveDate')}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={props.fieldsize || 3}>
        <Stack>
          <Typography variant="caption" gutterBottom>
            Expiry Date
          </Typography>
          <RHFTextField
            type="date"
            size="small"
            name={`${props.name}ExpiryDate`}
            InputProps={getInputProps(props, 'expiryDate')}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={props.fieldsize || 3}>
        <Stack>
          <Typography variant="caption" gutterBottom>
            Value
          </Typography>
          <RHFTextField label="" size="small" name={props.name} InputProps={getInputProps(props, 'value')} />
        </Stack>
      </Grid>
      {props?.formType === 'UPDATE_PROGRAM' && (
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle2" gutterBottom>
            &nbsp;&nbsp;
          </Typography>
          <Button
            startIcon={<NoteAltOutlined />}
            variant="soft"
            onClick={() => {
              props?.handleProgramAttributeUpdateAction(props.name, props.label, {
                effectiveDate: props.effectiveDate,
                expiryDate: props.expiryDate,
                id: props.id,
                name: props.name,
                programId: props.programId,
                value: props.value,
              });
            }}
          >
            edit
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default ProgramAttributeFieldItem;
