import { Container, Grid, Stack, Typography } from '@mui/material';

import ActivityImg from '../assets/dashboard-tiles/activity.webp';
import DriverImg from '../assets/dashboard-tiles/driver-mgt.webp';
import ProgramImg from '../assets/dashboard-tiles/program-mgt.webp';
import UserImg from '../assets/dashboard-tiles/user-mgt.webp';
import Page from '../components/Page';
import AppWidgetSummary from '../features/provado-app/app/AppWidgetSummary';
import { useAuth } from '../hooks/useAuth';

export default function ProvadoApp() {
  const authObj = useAuth();

  return (
    <Page title="ProvadoApp">
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ mb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
          <Typography variant="h4" color="primary">
            {authObj?.user?.first_name}!
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Providers" img={DriverImg} path="/provado-app/driver" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Contracts" color="info" img={ProgramImg} path="/provado-app/contract" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Users" color="warning" img={UserImg} path="/provado-app/user" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Trip & Earning" color="error" img={ActivityImg} path="/provado-app/activity" />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
