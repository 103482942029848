// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------
// TODO:- This is for reference. Remove once confirmed
// DASHBOARD
// PROVIDER
// CONTRACTS
// USERS
// ACTIVITY
// CREDENTIALS
// OPPORTUNITIES
// ANALYTICS
// RATES
// COVERAGE
// AVAILABILITY

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = (userType) => {
  switch (userType) {
    case 'DRIVER':
      return [
        {
          title: 'Profile',
          path: '/provado-app/profile',
          icon: getIcon('eva:people-fill'),
        },
        {
          title: 'Activity',
          path: '/provado-app/payment',
          icon: getIcon('uiw:pay'),
        },
        {
          title: 'Credentials',
          path: '/provado-app/credential',
          icon: getIcon('bi:file-text'),
        },
        {
          title: 'Vehicle',
          path: '/provado-app/vehicle',
          icon: getIcon('bi:file-text'),
        },
      ];
    default:
      return [
        {
          title: 'dashboard',
          path: '/provado-app/dashboard',
          icon: getIcon('eva:pie-chart-2-fill'),
        },
        {
          title: 'providers',
          path: '/provado-app/driver',
          icon: getIcon('healthicons:truck-driver'),
        },
        {
          title: 'contracts',
          path: '/provado-app/contract',
          icon: getIcon('ant-design:file-done-outlined'),
        },
        {
          title: 'users',
          path: '/provado-app/user',
          icon: getIcon('eva:people-fill'),
        },
        {
          title: 'activity',
          path: '/provado-app/activity',
          icon: getIcon('uiw:pay'),
        },
        {
          title: 'credentials',
          path: '/provado-app/credentials',
          icon: getIcon('carbon:credentials'),
        },
        {
          title: 'opportunities',
          path: '/provado-app/opportunities',
          icon: getIcon('ep:opportunity'),
        },
        {
          title: 'analytics',
          path: '/provado-app/analytics',
          icon: getIcon('carbon:analytics'),
        },
        {
          title: 'rates',
          path: '/provado-app/rates',
          icon: getIcon('material-symbols:star-rate-half'),
        },
        {
          title: 'coverage',
          path: '/provado-app/coverage',
          icon: getIcon('mdi:map-marker'),
        },
        {
          title: 'availability',
          path: '/provado-app/availability',
          icon: getIcon('material-symbols:event-available-outline'),
        },
      ];
  }
};

export default navConfig;
