//
import { Navigate, Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// material
// components
import Logo from '../components/Logo';
import { useAuth } from '../hooks/useAuth';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const authObject = useAuth();

  if (authObject?.user?.authenticated) {
    return <Navigate to="/provado-app/dashboard" />;
  }

  return (
    <>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
      <Outlet />
    </>
  );
}
