import doRequest from '../../lib/api';

import { ATTRIBUTE_API_URL } from './constant';

export function createAttribute(attribute) {
  const config = {
    url: ATTRIBUTE_API_URL,
    data: attribute,
    filterPayload: true,
  };
  return doRequest.post(config);
}
