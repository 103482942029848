import doRequest from '../../lib/api';

import { DRIVER_API_URL } from './constant';

export function updateDriver(driver, driverId) {
  const config = {
    url: `${DRIVER_API_URL}/${driverId}`,
    data: driver,
  };
  return doRequest.put(config);
}
