import { Container, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import BgImage from '../assets/bg_report-concern.jpeg';
import Page from '../components/Page';
import LoginForm from '../features/auth/LoginForm';
import { useAuth } from '../hooks/useAuth';
import { doLogin } from '../services/auth';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const StyledLoginPage = (props) => {
  const theme = useTheme();
  return (
    <Page
      title="Login"
      sx={{
        position: 'relative',
        '&:before': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          width: '100%',
          height: '100%',
          [theme.breakpoints.up('sm')]: {
            background: `url(${BgImage}) no-repeat center center`,
            opacity: 0.75,
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center top',
            backgroundSize: 'cover',
          },
        },
      }}
    >
      {props.children}
    </Page>
  );
};

// ----------------------------------------------------------------------

export default function Login() {
  const authObject = useAuth();

  const submitHandler = () => async (formData) => {
    const resp = await doLogin(formData);

    if (resp?.data?.authenticated) {
      authObject.login({
        authenticated: resp?.data?.authenticated,
        first_name: resp?.data?.first_name,
        last_name: resp?.data?.last_name,
        middle_initial: resp?.data?.middle_initial,
        user_type: resp?.data?.user_type, // DRIVER/ADMIN //resp?.data?.user_type
        email: formData?.username,
        token: resp?.headers?.authorization,
      });
    }
  };

  return (
    <StyledLoginPage>
      <RootStyle>
        <Container maxWidth="xs">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Sign in to the Provado Portal.
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

            <LoginForm onSubmit={submitHandler()} />
          </ContentStyle>
        </Container>
      </RootStyle>
    </StyledLoginPage>
  );
}
