import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { toUpper as _toUpper } from 'lodash';

import { ProtectedRoute } from './components/ProtectedRoute';
import { useAuth } from './hooks/useAuth';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import ProvadoAppLayout from './layouts/provado-app';
import ActivityPage from './pages/activity';
import AnalyticsPage from './pages/analytics';
import ChangePasswordPage from './pages/ChangePassword';
import DriverPage from './pages/driver';
import CreateDriverPage from './pages/driver/CreateDriver';
import EditDriverPage from './pages/driver/EditDriver';
import ViewDriverPage from './pages/driver/ViewDriver';
import ForgotPasswordPage from './pages/ForgotPassword';
import LoginPage from './pages/Login';
import NotFoundPage from './pages/Page404';
import ProgramPage from './pages/program';
import CreateProgramPage from './pages/program/CreateProgram';
import EditProgramPage from './pages/program/EditProgram';
import ViewProgramPage from './pages/program/ViewProgram';
import ProvadoAppPage from './pages/ProvadoApp';
import ProviderCredentialPage from './pages/self/provider/credentials';
import ProviderPaymentPage from './pages/self/provider/payment';
import ProviderProfilePage from './pages/self/provider/profile';
import ProviderVehiclePage from './pages/self/provider/vehicle';
// import UserPage from './pages/user';

// ----------------------------------------------------------------------

function getRoutesByRole(role) {
  const provadoAppRoutes = {
    path: '/provado-app',
    element: (
      <ProtectedRoute>
        <ProvadoAppLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: 'dashboard', element: <ProvadoAppPage /> },
      {
        path: 'contract',
        children: [
          { index: true, element: <ProgramPage /> },
          { path: 'create', element: <CreateProgramPage /> },
          { path: 'view/:id', element: <ViewProgramPage /> },
          { path: 'edit/:id', element: <EditProgramPage /> },
        ],
      },
      {
        path: 'driver',
        children: [
          { index: true, element: <DriverPage /> },
          { path: 'create', element: <CreateDriverPage /> },
          { path: 'view/:id', element: <ViewDriverPage /> },
          { path: 'edit/:id', element: <EditDriverPage /> },
        ],
      },
      // { path: 'user', element: <UserPage /> },
      { path: 'analytics', element: <AnalyticsPage /> },
      { path: 'activity', element: <ActivityPage /> },
    ],
  };
  switch (_toUpper(role)) {
    case 'DRIVER':
      return [
        {
          ...provadoAppRoutes,
          children: [
            { index: true, element: <Navigate to="/provado-app/payment" /> },
            { path: 'dashboard', element: <Navigate to="/provado-app/payment" /> },
            { path: 'profile', element: <ProviderProfilePage /> },
            { path: 'credential', element: <ProviderCredentialPage /> },
            {
              path: 'payment',
              children: [{ index: true, element: <ProviderPaymentPage /> }],
            },
            { path: 'vehicle', element: <ProviderVehiclePage /> },
          ],
        },
      ];
    default:
      return [{ ...provadoAppRoutes }];
  }
}

export default function Router() {
  const authObject = useAuth();

  return useRoutes([
    ...getRoutesByRole(authObject?.user?.user_type),
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/provado-app/dashboard" /> },
        { path: 'login', element: <LoginPage /> },
        { path: 'forgot-password', element: <ForgotPasswordPage /> },
        { path: 'change-password', element: <ChangePasswordPage /> },
        { path: '404', element: <NotFoundPage /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
