import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { map as _map, startCase as _startCase } from 'lodash';

import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import { getProgramById } from '../../services/program';
import { fDate } from '../../utils/formatTime';

const ProgramAttributeAccordionComponentList = (props) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content">
      <Typography color="primary" variant="button" gutterBottom={false}>
        {_startCase(props?.keyIndex)}
      </Typography>
    </AccordionSummary>
    <Divider sx={{ mb: 1 }} />
    <AccordionDetails>
      {_map(props?.prgAtrList, (prgAtr) => (
        <Grid container spacing={2} key={`program_attribute_item_${props?.keyIndex}_${prgAtr?.id}`} sx={{ mb: 1 }}>
          <Grid item md={4} xs={12}>
            <Typography variant="caption">Effective Date</Typography>
            <Typography variant="body1">{fDate(prgAtr?.effectiveDate)}</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="caption">Expiry Date</Typography>
            <Typography variant="body1">{fDate(prgAtr?.expiryDate)}</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="caption">Value</Typography>
            <Typography variant="body1">{prgAtr?.value}</Typography>
          </Grid>
        </Grid>
      ))}
    </AccordionDetails>
  </Accordion>
);

const ProgramAttributeAccordionComponent = (props) => {
  const [attributeList, setAttribute] = React.useState([]);

  React.useEffect(() => {
    if (props?.attributeList) {
      setAttribute(props.attributeList);
    }
  }, [props?.attributeList]);

  return React.useMemo(
    () => (
      <>
        {_map(attributeList, (prgAtr, keyIndex) => (
          <ProgramAttributeAccordionComponentList
            key={`program_attribute_${keyIndex}`}
            prgAtrList={prgAtr}
            keyIndex={keyIndex}
          />
        ))}
      </>
    ),
    [attributeList]
  );
};

export default function ViewProgram() {
  const { id } = useParams();
  const [program, setProgram] = React.useState({});

  const getProgramDetails = async (programId) => {
    const resp = await getProgramById(programId);
    setProgram(resp?.data);
  };

  React.useEffect(() => {
    getProgramDetails(id);
  }, [id]);

  return (
    <Page title="View Contract">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={8}>
          <Typography variant="h4">Contract Information</Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/provado-app/contract"
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          >
            Contract List
          </Button>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <Typography variant="body1">Contract Name</Typography>
                <Typography variant="subtitle1">{program?.programName}</Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography variant="body1">Contract State</Typography>
                <Typography variant="subtitle1">{program?.programState}</Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography variant="body1">Effective Date</Typography>
                <Typography variant="subtitle1">{fDate(program?.effectiveDate)}</Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography variant="body1">Expiry Date</Typography>
                <Typography variant="subtitle1">{fDate(program?.expiryDate)}</Typography>
              </Grid>
            </Grid>

            {/* Attributes */}
            <Box mb={6} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ProgramAttributeAccordionComponent attributeList={program?.attributeMap} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
