import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Link, Stack } from '@mui/material';
import * as Yup from 'yup';

import { FormProvider, RHFCheckbox, RHFTextField } from '../../components/hook-form';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    username: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (formData) => {
    props.onSubmit(formData);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} autocomplete={false} novalidate>
      <Stack spacing={3}>
        <RHFTextField size="small" name="username" label="Email address" />

        <RHFTextField
          size="small"
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />

        <Link variant="subtitle2" to="/forgot-password" component={RouterLink} underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
