import { useQuery } from '@tanstack/react-query';

import { getActivityById } from '../services/activity';

export function useProviderActivity({ id }) {
  const {
    isLoading,
    error,
    data: resp,
  } = useQuery({
    queryKey: [`providerActivity_${id}`],
    queryFn: () => getActivityById({ id }),
    refetchOnMount: false,
  });

  return {
    activity: resp?.data,
    isLoading,
    error,
    isError: !!error,
  };
}
