import React from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material';

import ConfirmationModal from '../../components/AppModal/Confirmation';
import { FileDropZone } from '../../components/FileDropZone';
import Page from '../../components/Page';
import ACTIVITY_PERIOD from '../../data/activity-period';
import ActivityDetailsDialog from '../../features/activity/ActivityDetails';
import ActivityTable from '../../features/activity/ActivityTable';
import DriverCalculatedPaymentTable from '../../features/driver/DriverCalculatedPaymentTable';
import {
  earningFileUpload,
  getActivityByProgramId as getActivityByProgramIdService,
  getActivityPeriod as getActivityPeriodService,
  tripFileUpload,
} from '../../services/activity';
import { getPrograms as getProgramsService } from '../../services/program';
import { getTripDetailsForProvider, getTripsByProviderId } from '../../services/trips';

const initialState = {
  programs: [],
  selectedProgram: {},
  selectedWeek: '',
  tabValue: '1',
  earningFile: {},
  earningFileUploading: false,
  tripFile: {},
  tripFileUploading: false,
  activityPeriod: [],
  activity: [],
  uploadActivityPeriod: {},
  activityDetails: false,
  showCalculateTable: false,
  fileUploadConfirmDialog: { open: false, uploadEarningFileErrorList: [], file: {}, fileName: '' },
  trips: [],
  providerTripDetails: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PROGRAMS':
      return { ...state, programs: action.payload };
    case 'SET_SELECTED_PROGRAMS':
      return { ...state, selectedProgram: action.payload };
    case 'SET_WEEK':
      return { ...state, selectedWeek: action.payload };
    case 'SET_EARNING_FILE':
      return { ...state, earningFile: action.payload };
    case 'SET_EARNING_FILE_UPLOAD':
      return { ...state, earningFileUploading: action.payload };
    case 'TOGGLE_FILE_UPLOAD_CONFIRM_DIALOG':
      return { ...state, fileUploadConfirmDialog: action.payload };
    case 'SET_TRIP_FILE':
      return { ...state, tripFile: action.payload };
    case 'SET_TRIP_FILE_UPLOAD':
      return { ...state, tripFileUploading: action.payload };
    case 'SET_TAB_VALUE':
      return { ...state, tabValue: action.payload };
    case 'SET_ACTIVITY_PERIOD':
      return { ...state, activityPeriod: action.payload };
    case 'SET_UPLOAD_ACTIVITY_PERIOD':
      return { ...state, uploadActivityPeriod: action.payload };
    case 'SET_ACTIVITY':
      return { ...state, activity: action.payload };
    case 'SET_TRIPS':
      return { ...state, trips: action.payload };
    case 'SET_ACTIVITY_DETAILS_DIALOG':
      return { ...state, activityDetails: action.payload };
    case 'SET_SHOW_CALCULATE_TABLE':
      return { ...state, showCalculateTable: action.payload };
    case 'SET_PROVIDER_TRIP_DETAILS':
      return { ...state, providerTripDetails: action.payload };
    case 'RESET':
      return { ...initialState };
    default:
      return { ...state };
  }
}

export default function ActivityPage() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleSelectUploadActivityChange = React.useCallback((event) => {
    const { value } = event.target;
    dispatch({ type: 'SET_UPLOAD_ACTIVITY_PERIOD', payload: value });
  }, []);

  const handleTabChange = (event, newValue) => {
    dispatch({ type: 'SET_TAB_VALUE', payload: newValue });
    if (newValue === '3' && state.selectedWeek) {
      getActivity({
        payload: { fromDate: state.selectedWeek?.fromDate, toDate: state.selectedWeek?.toDate },
      });
    }
  };

  const getPrograms = React.useCallback(async () => {
    const { data } = await getProgramsService();
    if (data) {
      dispatch({ type: 'SET_PROGRAMS', payload: data?.programList });
    }
  }, []);

  const getTrips = React.useCallback(async ({ fDate, tDate, driverId }) => {
    const { data } = await getTripsByProviderId({ fromDate: fDate, toDate: tDate, providerId: driverId });

    if (data) {
      dispatch({ type: 'SET_TRIPS', payload: data });
      dispatch({ type: 'SET_ACTIVITY_DETAILS_DIALOG', payload: true });
    }
  }, []);

  const getActivityPeriod = React.useCallback(async ({ programId }) => {
    const { data } = await getActivityPeriodService({ programId });
    if (data) {
      dispatch({ type: 'SET_ACTIVITY_PERIOD', payload: data });
    }
  }, []);

  const getActivity = React.useCallback(
    async ({ payload }) => {
      const { data } = await getActivityByProgramIdService({
        programId: state.selectedProgram?.programId,
        data: payload,
      });
      if (data) {
        dispatch({ type: 'SET_ACTIVITY', payload: data });
      }
    },
    [state.selectedProgram?.programId]
  );

  const handleProgramChange = React.useCallback(
    (event) => {
      dispatch({ type: 'SET_SELECTED_PROGRAMS', payload: event?.target?.value });
      getActivityPeriod({ programId: event?.target?.value?.programId });
    },
    [getActivityPeriod]
  );

  const tripFileSubmit = React.useCallback(
    async (event) => {
      event.preventDefault();
      dispatch({ type: 'SET_TRIP_FILE_UPLOAD', payload: true });

      const { data } = await tripFileUpload({
        fileName: state.tripFile?.name,
        file: state.tripFile,
        programId: state.selectedProgram?.programId,
      });
      if (data?.success) {
        dispatch({ type: 'SET_TRIP_FILE', payload: {} });
      }
      dispatch({ type: 'SET_TRIP_FILE_UPLOAD', payload: false });
    },
    [state.selectedProgram?.programId, state.tripFile]
  );

  const earningFileSubmit = async ({ fileName, file, fromDate, toDate, override, programId }) => {
    dispatch({ type: 'SET_EARNING_FILE_UPLOAD', payload: true });
    const { data } = await earningFileUpload({
      fileName,
      file,
      fromDate,
      toDate,
      override,
      programId,
    });
    if (data?.success) {
      dispatch({ type: 'SET_EARNING_FILE', payload: {} });
    }
    if (data?.uploadEarningFileErrorList) {
      dispatch({
        type: 'TOGGLE_FILE_UPLOAD_CONFIRM_DIALOG',
        payload: { open: true, uploadEarningFileErrorList: data?.uploadEarningFileErrorList, file, fileName },
      });
    }
    dispatch({ type: 'SET_EARNING_FILE_UPLOAD', payload: false });
  };

  const viewActivityDetails = ({ driverId }) => {
    getTrips({ fDate: state.selectedWeek?.fromDate, tDate: state.selectedWeek?.toDate, driverId });
  };

  const closeActivityDetails = () => {
    dispatch({ type: 'SET_ACTIVITY_DETAILS_DIALOG', payload: false });
    dispatch({ type: 'SET_TRIPS', payload: [] });
  };

  const handleShowCalculateTable = async ({ id }) => {
    const { data } = await getTripDetailsForProvider({ providerId: id });

    dispatch({ type: 'SET_PROVIDER_TRIP_DETAILS', payload: data });
    dispatch({ type: 'SET_ACTIVITY_DETAILS_DIALOG', payload: false });
    dispatch({ type: 'SET_SHOW_CALCULATE_TABLE', payload: true });
  };

  const handleCloseCalculateTable = () => {
    dispatch({ type: 'SET_ACTIVITY_DETAILS_DIALOG', payload: true });
    dispatch({ type: 'SET_SHOW_CALCULATE_TABLE', payload: false });
  };

  React.useEffect(() => {
    getPrograms();
  }, [getPrograms]);

  const handleWeekChange = React.useCallback(
    (event) => {
      const { value } = event.target;
      dispatch({ type: 'SET_WEEK', payload: value });
      getActivity({
        payload: { fromDate: value?.fromDate, toDate: value?.toDate },
      });
    },
    [getActivity]
  );

  return (
    <>
      <Page title="Activity">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Activity
            </Typography>
          </Stack>

          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  id="contract-select"
                  select
                  label="Contract & State"
                  value={state.selectedProgram}
                  onChange={handleProgramChange}
                >
                  {state.programs?.map((option) => (
                    <MenuItem key={option.programId} value={option}>
                      {`${option?.programName} - ${option?.programState}`}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>

          {state.selectedProgram && 'programId' in state.selectedProgram && (
            <TabContext value={state.tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange}>
                  <Tab label="Upload Trip File" value="1" disableRipple />
                  <Tab label="Upload Earning File" value="2" disableRipple />
                  <Tab label="Earnings" value="3" disableRipple />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ px: 0 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        {state.tripFileUploading ? (
                          <div>Uploading...</div>
                        ) : (
                          <>
                            <Box component="form" onSubmit={tripFileSubmit}>
                              <Typography variant="subtitle2" gutterBottom>
                                Select or Drop Trip File
                              </Typography>
                              <FileDropZone
                                maxFiles={1}
                                multiple={false}
                                accept={{
                                  'text/csv': ['.csv'],
                                  'application/vnd.ms-excel': ['.xls'],
                                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                }}
                                onFileSelected={(file) => {
                                  dispatch({ type: 'SET_TRIP_FILE', payload: file[0] });
                                }}
                              />
                              <Box p={1} />
                              <Button
                                variant="contained"
                                fullWidth
                                startIcon={<FileUploadIcon />}
                                type="submit"
                                disabled={!state.tripFile?.name}
                              >
                                Upload
                              </Button>
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2" sx={{ px: 0 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            select
                            label="Select Activity Period"
                            onChange={handleSelectUploadActivityChange}
                            value={state.uploadActivityPeriod}
                          >
                            {ACTIVITY_PERIOD.map((option, index) => (
                              <MenuItem key={`week-with-date-${option?.uid}-${index}`} value={option}>
                                {option?.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        {state.earningFileUploading ? (
                          <div>Uploading...</div>
                        ) : (
                          <>
                            {Object.keys(state.uploadActivityPeriod).length > 0 && (
                              <Box
                                component="form"
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  earningFileSubmit({
                                    fileName: state.earningFile?.name,
                                    file: state.earningFile,
                                    fromDate: state.uploadActivityPeriod?.fromDate,
                                    toDate: state.uploadActivityPeriod?.toDate,
                                    override: false,
                                    programId: state.selectedProgram?.programId,
                                  });
                                }}
                              >
                                <Typography variant="subtitle2" gutterBottom>
                                  Select or Drop Earnings File
                                </Typography>
                                <FileDropZone
                                  maxFiles={1}
                                  multiple={false}
                                  accept={{
                                    'text/csv': ['.csv'],
                                    'application/vnd.ms-excel': ['.xls'],
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                  }}
                                  onFileSelected={(file) => {
                                    dispatch({ type: 'SET_EARNING_FILE', payload: file[0] });
                                  }}
                                />
                                <Box p={1} />
                                <Button
                                  variant="contained"
                                  fullWidth
                                  startIcon={<FileUploadIcon />}
                                  type="submit"
                                  disabled={!state.earningFile?.name}
                                >
                                  Upload
                                </Button>
                              </Box>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="3" sx={{ px: 0 }}>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        id="week-select"
                        select
                        label="Select Activity Period"
                        value={state.selectedWeek}
                        onChange={handleWeekChange}
                      >
                        {state.activityPeriod.map((option, index) => (
                          <MenuItem key={`week-with-date-${option?.activityPeriod}-${index}`} value={option}>
                            {option?.activityPeriod}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  {state.selectedWeek && (
                    <>
                      {!state.activityDetails && !state.showCalculateTable && (
                        <Grid item xs={12}>
                          <ActivityTable payment={state.activity} handleViewAction={viewActivityDetails} />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <ActivityDetailsDialog
                          open={state.activityDetails}
                          handleClose={closeActivityDetails}
                          trips={state.trips}
                          showCalculateTable={handleShowCalculateTable}
                        />
                      </Grid>
                      {state.showCalculateTable && (
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mb={2}>
                            <Typography variant="subtitle1">Guaranteed Earning Calculation Details</Typography>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={(e) => {
                                e.preventDefault();
                                handleCloseCalculateTable();
                              }}
                            >
                              Back
                            </Button>
                          </Stack>

                          <DriverCalculatedPaymentTable providerTripDetails={state.providerTripDetails} />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </TabPanel>
            </TabContext>
          )}
        </Container>
      </Page>

      <ConfirmationModal
        title="Action Required!"
        isOpen={state.fileUploadConfirmDialog.open}
        confirmBtnText="Continue Upload"
        cancelBtnText="Cancel Upload"
        onConfirm={() => {
          earningFileSubmit({
            fileName: state.fileUploadConfirmDialog.fileName,
            file: state.fileUploadConfirmDialog.file,
            fromDate: state.uploadActivityPeriod?.fromDate,
            toDate: state.uploadActivityPeriod?.toDate,
            override: true,
            programId: state.selectedProgram?.programId,
          });
          dispatch({
            type: 'TOGGLE_FILE_UPLOAD_CONFIRM_DIALOG',
            payload: { open: false, uploadEarningFileErrorList: [], file: {}, fileName: '' },
          });
        }}
        onCancel={() => {
          dispatch({ type: 'SET_EARNING_FILE', payload: {} });
          dispatch({
            type: 'TOGGLE_FILE_UPLOAD_CONFIRM_DIALOG',
            payload: { open: false, uploadEarningFileErrorList: [], file: {}, fileName: '' },
          });
        }}
      >
        <Stack spacing={2}>
          {state.fileUploadConfirmDialog.uploadEarningFileErrorList.map((item, index) => (
            <Alert
              severity={`${item?.severity && item?.severity.toLowerCase()}`}
              key={`uploadEarningFileErrorList${index}`}
            >
              {item?.message}
            </Alert>
          ))}

          <Typography variant="subtitle1">Do you want to continue with this file?</Typography>
        </Stack>
      </ConfirmationModal>
    </>
  );
}
