import { forEach as _forEach } from 'lodash';

export const validateForm = ({ requiredFields, fieldError }) => {
  let validFieldCount = 0;
  _forEach(requiredFields, (fieldName) => {
    if (fieldError[fieldName] !== undefined && fieldError[fieldName] === false) {
      validFieldCount += 1;
    }
  });
  return validFieldCount === requiredFields.length;
};
