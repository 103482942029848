import doRequest from '../../lib/api';

import { VALIDATE_PROGRAM_API_URL } from './constant';

export function validateProgram(program) {
  const config = {
    url: VALIDATE_PROGRAM_API_URL,
    data: program,
  };
  return doRequest.post(config);
}
