import doRequest from '../../lib/api';

import { MY_SERVER_SESSION } from './constant';

export function getServerSession() {
  const config = {
    url: MY_SERVER_SESSION,
  };
  return doRequest.get(config);
}
