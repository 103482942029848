import doRequest from '../../lib/api';

import { COUNTY_API_URL, SUPPORT_API_URL } from './constant';

export function getCountyByState(stateCode) {
  const config = {
    url: `${SUPPORT_API_URL}/${stateCode}${COUNTY_API_URL}`,
  };
  return doRequest.get(config);
}
