import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { getServerSession } from '../services/auth';

export const ProtectedRoute = ({ children }) => {
  const authObject = useAuth();

  React.useEffect(() => {
    if (authObject?.user?.authenticated) {
      getServerSession();
    }
    return () => {};
  }, [authObject?.user?.authenticated]);

  if (!authObject?.user?.authenticated) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};
