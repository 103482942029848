import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import * as store from 'store';

import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { fDate } from '../../utils';

import PaymentListHead from './DriverPaymentListHead';

function getTableHeader({ user }) {
  if (user && user.toLowerCase() === 'driver')
    return [
      { id: 'activityPeriod', label: 'Activity Period', alignRight: false },
      { id: 'fromDate', label: 'From Date', alignRight: false },
      { id: 'toDate', label: 'To Date', alignRight: false },
      { id: 'actualPayment', label: 'Payment', alignRight: true },
      { id: 'download', label: '' },
    ];

  return [
    { id: 'activityPeriod', label: 'Activity Period', alignRight: false },
    { id: 'payment', label: 'Actual', alignRight: true },
    { id: 'estimatedPayment', label: 'Guaranteed', alignRight: true },
  ];
}

export default function DriverPaymentTable(props) {
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const authObject = store.get('user');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props?.payment.length) : 0;

  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table size="small">
            <PaymentListHead
              order={order}
              orderBy={orderBy}
              headLabel={getTableHeader({ user: authObject?.user_type })}
              rowCount={props?.payment.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {props?.payment.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { guranteedPayment, actualPayment, toDate, fromDate, activityPeriod } = row;

                return authObject?.user_type === 'DRIVER' ? (
                  <TableRow key={activityPeriod}>
                    <TableCell align="left">{activityPeriod}</TableCell>
                    <TableCell align="left">{fDate(fromDate)}</TableCell>
                    <TableCell align="left">{fDate(toDate)}</TableCell>
                    <TableCell align="right">{`$${actualPayment}`}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="text"
                        size="small"
                        startIcon={<Iconify icon="material-symbols:download" width={24} height={24} />}
                        onClick={(event) => {
                          event.preventDefault();
                          if (props.handleActivityDownloadAction) {
                            props.handleActivityDownloadAction({ fromDate, toDate });
                          }
                        }}
                      >
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={activityPeriod}>
                    <TableCell align="left">{activityPeriod}</TableCell>
                    <TableCell align="right">{`$${actualPayment}`}</TableCell>
                    <TableCell align="right">{`$${guranteedPayment}`}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props?.payment.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
