import doRequest from '../../lib/api';

import { DRIVER_API_URL } from './constant';

export function getDrivers() {
  const config = {
    url: DRIVER_API_URL,
  };
  return doRequest.get(config);
}

export function getDriverById(driverId) {
  const config = {
    url: `${DRIVER_API_URL}/${driverId}`,
  };
  return doRequest.get(config);
}
