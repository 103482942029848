import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { endOfYear, format } from 'date-fns';
import * as Yup from 'yup';

import { FormProvider } from '../../components/hook-form';

import ProgramAttributeFieldItem from './ProgramAttributeFieldItem';

function prepareData({ formData, name, programId }) {
  return {
    name,
    programId,
    effectiveDate: format(formData[`${name}EffectiveDate`], 'yyyy-MM-dd'),
    expiryDate: format(formData[`${name}ExpiryDate`], 'yyyy-MM-dd'),
    value: formData[`${name}`],
  };
}

function AddAndUpdateAttributeDialog(props) {
  const numberReg = /^\d+\.?\d*$/;
  const programAttributeSchema = {};
  const programAttributeDefaultValues = {};

  programAttributeSchema[props?.name] = Yup.string().matches(numberReg, 'Not a valid number!').required('Required!');
  programAttributeSchema[`${props?.name}EffectiveDate`] = Yup.date().required('Effective date is required!');
  programAttributeSchema[`${props?.name}ExpiryDate`] = Yup.date().required('Expiry date is required!');
  programAttributeDefaultValues[props?.name] = props?.state?.value || '';
  programAttributeDefaultValues[`${props?.name}EffectiveDate`] =
    props?.state?.effectiveDate || format(new Date(), 'yyyy-MM-dd');
  programAttributeDefaultValues[`${props?.name}ExpiryDate`] =
    props?.state?.expiryDate || format(endOfYear(new Date()), 'yyyy-MM-dd');

  const attributeMethods = useForm({
    resolver: yupResolver(Yup.object().shape({ ...programAttributeSchema })),
    defaultValues: programAttributeDefaultValues,
  });

  const {
    handleSubmit: handleAttributeSubmit,
    formState: { isSubmitting: isAttributeSubmitting },
  } = attributeMethods;

  const onSubmit = (data) => {
    props?.onSubmit(prepareData({ formData: data, name: props?.name, programId: props?.programId }));
  };

  const handleClose = () => {
    props?.handleClose();
  };

  return (
    <Dialog open={props?.open} onClose={handleClose} fullWidth>
      <FormProvider methods={attributeMethods} onSubmit={handleAttributeSubmit(onSubmit)}>
        <DialogTitle>{`${props?.title} For ${props?.label}`}</DialogTitle>

        <DialogContent dividers>
          {props?.open && <ProgramAttributeFieldItem name={props?.name} fieldsize={4} />}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="soft" loading={isAttributeSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default AddAndUpdateAttributeDialog;
