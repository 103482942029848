import doRequest from '../../lib/api';

import { DRIVER_API_URL } from './constant';

export function activateDeactivateDriver(driverId, activate) {
  const config = {
    url: `${DRIVER_API_URL}/${driverId};isActive=${activate}`,
  };
  return doRequest.post(config);
}
