//
// @mui
import { NavLink as RouterLink } from 'react-router-dom';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Link } from '@mui/material';
import PropTypes from 'prop-types';

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, path, img, color = 'primary', sx, ...other }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card
          sx={{
            textAlign: 'center',
            color: (theme) => theme.palette[color].darker,
            ...sx,
          }}
          {...other}
        >
          <CardActionArea LinkComponent={RouterLink} to={path}>
            <CardMedia component="img" height="180" image={img} alt="green iguana" />
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Link
          color={color}
          variant="h6"
          sx={{ cursor: 'pointer', textAlign: 'center', display: 'block' }}
          component={RouterLink}
          to={path}
        >
          {title}
        </Link>
      </Grid>
    </Grid>
  );
}
