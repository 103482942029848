import doRequest from '../../lib/api';

import { AUTHENTICATE_API_URL } from './constant';

export function doLogin(user) {
  const config = {
    url: AUTHENTICATE_API_URL,
    data: user,
  };
  return doRequest.post(config);
}
