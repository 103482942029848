import Add from '@mui/icons-material/Add';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';

import { FromTitle } from '../../components/Titles';

import ProgramAttributeFieldItem from './ProgramAttributeFieldItem';

function ProgramAttributeFieldList(props) {
  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FromTitle title={props.label} divider>
            {props?.formType === 'UPDATE_PROGRAM' && (
              <Button
                variant="soft"
                startIcon={<Add />}
                onClick={(event) => {
                  event.preventDefault();
                  props?.handleProgramAttributeAddAction(props?.name, props?.label);
                }}
              >
                add
              </Button>
            )}
          </FromTitle>
        </Grid>
        <Grid item xs={12}>
          {props?.programAttributeList ? (
            props?.programAttributeList.map((eachAttr, index) => (
              <ProgramAttributeFieldItem
                key={`program-attribute-field-item-${index}-${eachAttr.id}`}
                {...props}
                {...eachAttr}
              />
            ))
          ) : (
            <ProgramAttributeFieldItem {...props} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProgramAttributeFieldList;
