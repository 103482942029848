import { format } from 'date-fns';
import { snakeCase as _snakeCase } from 'lodash';

export function DriverFormGeneratePayload({ formData, credentials, programList }) {
  const selectedProgram = programList.filter((program) => program?.programState === formData?.programId);

  const data = {
    program_id: selectedProgram[0]?.programId,
    county: formData?.county,
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    middleName: formData?.middleName,
    licenseNumber: formData?.licenseNumber,
    address: formData?.address,
    city: formData?.city,
    state: formData?.state,
    zipCode: formData?.zipCode,
    country: 'USA',
    phone: formData?.phone,
    email: formData?.email,
    verified: formData?.verified,
    isActive: formData?.active,
    minWagePerHour: formData?.minWagePerHour,
    vehicle_type: formData?.vehicleType,
    vehicle_year: formData?.vehicleYear,
    vehicle_make: formData?.vehicleMake,
    vehicle_model: formData?.vehicleModel,
    vehicle_color: formData?.vehicleColor,
    vehicle_state: formData?.vehicleState,
    vehicle_plate: formData?.vehiclePlate,
    vehicle_vin: formData?.vehicleVIN,
    vehicle_los: { vehicle_los: formData?.vehicleLOS },
    vehicle_power: formData?.vehiclePower,
  };

  if (credentials && credentials.length > 0) {
    data.credentials = credentials.map((element) => ({
      name: _snakeCase(element?.credentialName),
      expDate: format(formData[`cred_${_snakeCase(element?.credentialName)}_date`], 'yyyy-MM-dd'),
      description: element?.credentialDescription,
    }));
  }

  return data;
}
