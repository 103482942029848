import doRequest from '../../lib/api';
import { DRIVER_API_URL } from '../driver/constant';

import { ACTIVITY_API_URL, ACTIVITY_DOWNLOAD_API_URL } from './constant';

export function getActivityDownloadById({ id, fromDate, toDate }) {
  const config = {
    url: `${DRIVER_API_URL}/${id}${ACTIVITY_API_URL}${ACTIVITY_DOWNLOAD_API_URL}`,
    responseType: 'blob',
    data: {
      fromDate,
      toDate,
    },
  };
  return doRequest.post(config);
}
