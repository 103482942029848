// material
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, Card, Container, Fab, Stack, Typography } from '@mui/material';

import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import { PageTitle } from '../../components/Titles';
import DriverForm from '../../features/driver/DriverForm';
// service
import { createDriver } from '../../services/driver';

export default function CreateDriver() {
  const navigate = useNavigate();

  const onSubmit = () => async (data) => {
    const resp = await createDriver(data);
    if (resp?.data) {
      navigate('/provado-app/driver', { replace: true });
    }
  };

  return (
    <Page title="Create Provider">
      <Container maxWidth="xl">
        <PageTitle title="Create Provider">
          <Button
            variant="outlined"
            component={RouterLink}
            to="/provado-app/driver"
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          >
            Provider List
          </Button>
        </PageTitle>

        <Card sx={{ padding: 2 }}>
          <DriverForm onSubmit={onSubmit()} formType="CREATE_DRIVER" />
        </Card>
      </Container>
    </Page>
  );
}
