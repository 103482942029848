// material
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Container, Stack, Typography } from '@mui/material';

import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import { PageTitle } from '../../components/Titles';
import DriverTable from '../../features/driver/DriverTable';
// service
import { activateDeactivateDriver, getDrivers as getDriverService } from '../../services/driver';

// ----------------------------------------------------------------------

export default function Driver() {
  const [drivers, setDrivers] = React.useState([]);

  const getDrivers = () => {
    getDriverService().then((value) => {
      setDrivers(value?.data?.driverList);
    });
  };

  const updateDriverStatus = (driverId, status) => {
    activateDeactivateDriver(driverId, status).then((resp) => {
      if (resp) {
        getDrivers();
      }
    });
  };

  React.useEffect(() => {
    getDrivers();
  }, []);

  const handleToggleActivation = (driverId, active) => {
    updateDriverStatus(driverId, active);
  };

  return (
    <Page title="Driver">
      <Container maxWidth="xl">
        <PageTitle title="Providers">
          <Button
            variant="outlined"
            component={RouterLink}
            to="/provado-app/driver/create"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add New Provider
          </Button>
        </PageTitle>
        <DriverTable drivers={drivers} handleToggleActivation={handleToggleActivation} />
      </Container>
    </Page>
  );
}
