import { useQuery } from '@tanstack/react-query';

import { getProviderProfile } from '../services/profile';

export function useProviderProfile() {
  const {
    isLoading,
    error,
    data: resp,
  } = useQuery({
    queryKey: ['providerProfile'],
    queryFn: () => getProviderProfile(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    profile: resp?.data,
    isLoading,
    error,
    isError: !!error,
  };
}
