import React from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { APP_BAR_DESKTOP, APP_BAR_MOBILE } from './config';
import ProvadoAppNavbar from './ProvadoAppNavbar';
import ProvadoAppSidebar from './ProvadoAppSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 16,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 16,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function ProvadoAppLayout() {
  const [open, setOpen] = React.useState(true);

  return (
    <RootStyle>
      <ProvadoAppNavbar onOpenSidebar={() => setOpen(true)} />
      <ProvadoAppSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
